import api from '../interceptors/auth.interceptor';

class GraphsService {
  // getAll() {
  //   return api.get(process.env.REACT_APP_API_URL + '/api/graphs');
  // }

  get(graphId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/graphs/${graphId}`);
  }

  update(graphId, graphData) {
    return api.post(process.env.REACT_APP_API_URL + `/api/graphs/${graphId}`, {graph: graphData});
  }


  create(graphData) {
      return api.post(process.env.REACT_APP_API_URL + `/api/graphs/`, 
        {graph: graphData}
      );
    }
}

const graphsService = new GraphsService();
export default graphsService;
import { useState, useEffect } from 'react';

import userGraphsService from '../services/user-graphs.service';
import addTitleToDefinition from '../utils/add-title-to-definition';


export default function GraphSelector({defaultTitle = "Choose Graphs", 
  setErrorMessage, 
  setSuccessMessage, 
  setLoading=null,
  onGraphsPicked, 
  // entriesDefinitions = null,
  initialValue = [], 
  allowChooseAll=true,
  allowFilter=false,
  allowOnlySingleSelection=false,
  }
) {
  const [title, setTitle] = useState(defaultTitle);
  const [allGraphs, setAlGraphs] = useState([])
  const [filteredGraphs, setFilteredGraphs] = useState([]);
  const [chosenGraphs, setChosenGraphs] = useState(JSON.parse(JSON.stringify(initialValue)))

  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  useEffect(() => {
    if (onGraphsPicked) {
      onGraphsPicked(chosenGraphs);
    }
  }, [chosenGraphs])

  useEffect(() => {
    // let action;

    // if (entriesDefinitions) {
    //   action = Promise.resolve({data: {definitions: entriesDefinitions}});
    // } else {
      // action = userEntriesService.getEntriesDefinitions();
    // }

    userGraphsService.getAll().then(
      response => {
        const localAllGraphs = response.data.graphs.map((graph) => ({...addTitleToDefinition(graph)}));
        setAlGraphs(localAllGraphs);
        setFilteredGraphs(localAllGraphs);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
    
  }, [])

  const onCheckedGraph = (event) => {
    event.preventDefault();
    const graphId = parseInt(event.target.value);
    const graph = allGraphs.find((graph) => graph.id === graphId);
    if (graph) {
      if (event.target.checked) {
        if (allowOnlySingleSelection) {
          setChosenGraphs([graph])
        } else {
          setChosenGraphs([...chosenGraphs, graph]);
        }
      } else {
        setChosenGraphs(chosenGraphs.filter((chosenGraph) => chosenGraph.id !== graph.id));
      }
    }
  }

  const isChecked = (graph) => {
    return chosenGraphs.some((chosenGraph) => chosenGraph.id === graph.id);
  }

  const setAutoComplete = (e) => {
    if (allGraphs.length === 0) return;

    if (setLoading) {
      setLoading(true);
    }

    let text = e.target.value.toLowerCase();
    if (text === '') {
      setFilteredGraphs(JSON.parse(JSON.stringify(allGraphs)))
      if (setLoading) {
        setLoading(false);
      }
      return;
    }
    const localFilteredGraphs = allGraphs.filter((graph) => graph.name.toLowerCase().includes(text))
      // .reduce((cur, key) => { return Object.assign(cur, { [key]: allDefinitions[key] })}, {});
    setFilteredGraphs(localFilteredGraphs || [] );
    if (setLoading) {
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="bg-white border-bottom-0 h4 font-weight-light">{title}</div>
      {allowFilter && 
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Filter" aria-label="Filter" aria-describedby="Filter Definitions" 
          onChange={setAutoComplete}
          />
      </div>
      }
      {allowChooseAll && 
        <div className="input-group mb-3">
          <input className="form-check-input me-1" type="checkbox" value="" id="chooseAll" onChange={(event) => {
            if (event.target.checked) {
              setChosenGraphs(filteredGraphs);
            } else {
              setChosenGraphs([]);
            }
          }} checked={chosenGraphs.length === filteredGraphs.length} />
          Choose All
        </div>
      }
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              
            </th>
            <th></th>
          </tr>
          <tr>
            <th scope="col">Select</th>
            <th scope="col">Graph</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {filteredGraphs.length > 0 && filteredGraphs.map((graph) => (
          <tr key={graph.id}>
            <td className="col-1">
              <input className="form-check-input" key={`select-${graph.id}`} 
                type="checkbox" 
                value={graph.id} 
                id={graph.id} 
                onChange={onCheckedGraph} 
                checked={isChecked(graph)}
                /> 
            </td>
            <td>
              {graph.title}
            </td>
            <td>
              {graph.description}
            </td>
          </tr>
          ))}
        </tbody>
      </table>
   </div>
  )
}
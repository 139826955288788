import axios from 'axios';
import authHeader from './auth-header';
import api from '../interceptors/auth.interceptor';

class UserService {

  get(userName) {
    if (userName) {
      return api.get(process.env.REACT_APP_API_URL + `/api/users/${userName}`);
    } else {
      return api.get(process.env.REACT_APP_API_URL + `/api/user/`);
    }
  }

  getCategories() {
    return api.get(process.env.REACT_APP_API_URL + '/api/user/categories');
  }
  
  getGroups() {
    return api.get(process.env.REACT_APP_API_URL + '/api/user/groups');
  }

  getViews() {
    return api.get(process.env.REACT_APP_API_URL + '/api/user/views');
  }

  getInterventions() {
    return api.get(process.env.REACT_APP_API_URL + '/api/user/interventions');
  }

  addGroup(groupUuid) {
    return api.put(process.env.REACT_APP_API_URL + `/api/user/groups/${groupUuid}`);
  }

  removeGroup(groupUuid) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/user/groups/${groupUuid}`);
  }

  addView(viewUuid) {
    return api.put(process.env.REACT_APP_API_URL + `/api/user/views/${viewUuid}`);
  }

  removeView(viewUuid) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/user/views/${viewUuid}`);
  }

  getApplications() {
    return api.get(process.env.REACT_APP_API_URL + '/api/user/applications');
  }

  // addApplications(applicationName) {
  //   return api.put(process.env.REACT_APP_API_URL + `/api/user/applications/${encodeURIComponent(applicationName)}`);
  // }

  removeApplication(applicationName) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/user/applications/${applicationName}`);
  }

  getPublicContent() {
    return api.get(process.env.REACT_APP_API_URL + '/api/content/all');
  }

  getUserBoard(applicationId) {
    return api.get(`${process.env.REACT_APP_API_URL}/api/test/user${applicationId ? `/${applicationId}` : ''}`);
  }

  getModeratorBoard() {
    return axios.get(process.env.REACT_APP_API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(process.env.REACT_APP_API_URL + 'admin', { headers: authHeader() });
  }
}

const userService = new UserService();
export default userService;
import React, {useState, useEffect} from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import MultipleTrackingCharts from "./multiple-tracking-charts.component";
import Alert from './alert.component';

import userService from "../services/user.service";
import userConfigurationService from '../services/user-configuration.service.js';
import userInterventionService from '../services/user-intervention.service.js';
import definitionsService from "../services/definitions.service.js";
import viewService from "../services/view.service.js";

export default function BoardUser({defaultViews = [], defaultGroups = [], defaultCategories, defaultYhpApplications = [], userName}) {
  const [loading, setLoading] = useState(false)
  const [chosenGroupIdx, setChosenGroupIdx] = useState(0);
  const [groups, setGroups] = useState([]);
  const [chosenGroup, setChosenGroup] = useState()
  const [chosenViewIdx, setChosenViewIdx] = useState(0);
  const [preChosenDefinitionId, setPreChosenDefinitionId] = useState(-1);
  const [preChosenDefinition, setPreChosenDefinition] = useState({});
  const [originalViews, setOriginalViews] = useState([]);
  const [views, setViews] = useState([]);
  const [chosenIntervention, setChosenIntervention] = useState({})
  const [interventions, setInterventions] = useState([]);
  const [showDates, setShowDates] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [chosenView, setChosenView] = useState()
  const [configurations, setConfigurations] = useState();
  let [searchParams, setSearchParams] = useSearchParams();
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { viewUuid } = useParams();

  // got preChosenDefinitionId - get the data for this definition 
  useEffect(() => {
    if (preChosenDefinitionId === -1) {
      setPreChosenDefinition({})
    } else {
      definitionsService.get(preChosenDefinitionId).then(
        response => {
          setPreChosenDefinition(response.data.definition)
        },
        error => {
          setAlert(alert => ({...alert, 
            show: true,
            alertType: 'danger',
            alertTitle: 'Error',
            alertMessage: (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }));
        }
      )
    }
  }, [preChosenDefinitionId])

  useEffect(() => {
    if (searchParams.has('definition')) {
      const localChosenDefinitionId = parseInt(searchParams.getAll('definition')[0]);
      setPreChosenDefinitionId(localChosenDefinitionId);
      if (views.length > 0) {
        const firstViewIndex = views.findIndex((view) => 
          view.categories.findIndex((category) => 
            category.definitions.findIndex((definition) => definition.id === localChosenDefinitionId) !== -1
          ) !== -1
        )

        if (firstViewIndex !== -1) {
          setChosenViewIdx(firstViewIndex);
        }
      }
    };
    if (searchParams.has('group')) {
      if (groups.length === 0) return;

      const groupId = searchParams.getAll('group')[0];
      const groupIndex = groups.findIndex((group) => group.id === parseInt(groupId));
      if (groupIndex !== -1) {
        setChosenGroupIdx(groupIndex);
      }
    };
  }, [groups, searchParams, views])

  useEffect(() => {
    if (chosenGroupIdx === -1 || groups.length === 0 /*|| (chosenGroup && chosenGroup.name === groups[chosenGroupIdx].name)*/) {
      return
    } 
    setChosenGroup(groups[chosenGroupIdx])
    localStorage.setItem("chosenGroup", JSON.stringify({
      name: groups[chosenGroupIdx].name,
      id: groups[chosenGroupIdx].id
    }));
  }, [groups, chosenGroupIdx])

  useEffect(() => {
    if (chosenViewIdx === -1 || views.length === 0) {
      return
    } 
    localStorage.setItem("chosenView", JSON.stringify({
      name: views[chosenViewIdx].name,
      id: views[chosenViewIdx].id
    }));
    setChosenView(views[chosenViewIdx])
  }, [chosenViewIdx, views])

  useEffect(() => {
    setLoading(true);
    Promise.all([
      userService.getGroups(),
      userService.getViews()
    ]).then(
      responses => {
        const groupsResponse = responses[0].data.groups || [];
        const viewsResponse = responses[1].data.views || [];
        const localGroups = groupsResponse.concat(defaultGroups)
        setGroups(localGroups)
        const localViews = (viewsResponse || []).concat(defaultViews);

        setViews(localViews)
        setOriginalViews(localViews)

        if (!searchParams.has('group')) {
          const savedChosenGroup = JSON.parse(localStorage.getItem('chosenGroup'))
          let groupIndex;
          if (savedChosenGroup && savedChosenGroup !== -1) {
            groupIndex = localGroups.findIndex((group) => group.name === savedChosenGroup.name);
          }

          if (!groupIndex) groupIndex = 0;
          setChosenGroupIdx(groupIndex);
        }

        // if definition was chosen view will be chosen from it
        if (!searchParams.has('definition')) {
          const savedChosenView = JSON.parse(localStorage.getItem('chosenView'))
          let viewIndex;

          if (viewUuid) {
            viewIndex = localViews.findIndex((view) => view.uuid === viewUuid);
          } else if (savedChosenView && savedChosenView !== -1) {
            viewIndex = localViews.findIndex((view) => view.name === savedChosenView.name);
          }

          
          if (!viewIndex) viewIndex = 0;
          setChosenViewIdx(viewIndex)
        }

        setLoading(false);
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    );
  }, [])

  useEffect(() => {
    if (!chosenGroup) return;
    userConfigurationService.get(chosenGroup.id).then(
      response => {
        const groupWithConfiguration = response.data.groups_with_configurations.find((groupWithConfiguration) => groupWithConfiguration.id === chosenGroup.id);
        setConfigurations({
          groupConfiguration: groupWithConfiguration ? groupWithConfiguration.user_group_configuration : {}, 
          userConfiguration: response.data.user_configuration
        });
      },
      error => {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    );
  }, [chosenGroup])

  useEffect(() => {
    userInterventionService.getAll().then(
      response => {
        setInterventions(response.data.interventions);
      },
      error => {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
  )}, []);

  const handleDismissAlert = (e) => {
    e.preventDefault();
    setPreChosenDefinitionId(-1);
    searchParams.delete('definition');
    searchParams.delete('group');
    setSearchParams(searchParams);
  };

  const handleGroupChange = (selectedOption) => {
    const groupIndex = groups.findIndex((group) => group.name === selectedOption.target.text);
    setChosenGroupIdx(groupIndex);
  };

  const handleViewChange = (selectedOption) => {
    const viewIndex = views.findIndex((view) => view.name === selectedOption.target.text);
    setChosenViewIdx(viewIndex);
  };

  const handleInterventionChange = (interventionUuid) => {
    return (selectedOption) => {
      if (!interventionUuid) {
        localStorage.removeItem("chosenIntervention");
        setChosenIntervention({});
        setViews(originalViews);
      } else {
        const intervention = interventions.find((intervention) => intervention.uuid === interventionUuid);

        localStorage.setItem("chosenIntervention", JSON.stringify({
          uuid: intervention.uuid,
        }));

        const view = originalViews.find((view) => view.id === intervention.view_id);

        if (!view) {
          setLoading(true);
          userService.getView(intervention.view.uuid).then((
            response => {
              setLoading(false);
              const view = response.data.view;
              setViews([view]);
              setChosenView(view);
              setChosenIntervention(intervention);
              setViews([view]);
              setChosenViewIdx(0)
            }
          ), (
            error => {
              setAlert(alert => ({...alert, 
                show: true,
                alertType: 'danger',
                alertTitle: 'Error',
                alertMessage: (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
              }));
            }
          ))
        } else {
          setChosenIntervention(intervention);
          setViews([view]);
          setChosenViewIdx(0)
        }
      }
    }
  }

  const duplicateView = () => {
    if (!chosenView.uuid && !chosenView.application_id) {
      return Promise.reject('Cannot duplicate this view');
    }
    return viewService.duplicate(chosenView.uuid, chosenView.application_id).then(
      response => {
        const view = response.data.view;
        setViews([...views, view]);
        setChosenView(view);
        setChosenViewIdx(views.length);
        return view;
      },
      error => {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
        throw error;
      }
    )
  }

  const displayTabs = () => {
    if (!chosenView.categories) return;

    let viewDefinitions = chosenView.categories.reduce((acc, category) => acc.concat(category.definitions), []);
    let viewCategories;
    if (preChosenDefinitionId !== -1) {
      viewCategories = chosenView.categories.filter(category => category.definitions.some(definition => definition.id === preChosenDefinitionId));
    } else {
      viewCategories = chosenView.categories;
    }

    const returnTabs =
      viewCategories.map((category) => {
        viewDefinitions = viewDefinitions.filter(viewDefinition => !category.definitions.some(categoryDefinition => categoryDefinition.name === viewDefinition.name));

        let dateRange;
        if (chosenIntervention.started_at && chosenIntervention.ended_at) {
          dateRange = {
            started_at: chosenIntervention.started_at,
            ended_at: chosenIntervention.ended_at
          }
        } else if (fromDate || toDate) {
          dateRange = {
            started_at: fromDate,
            ended_at: toDate
          }
        }

        return (<Tab eventKey={category.name} title={category.name} key={category.name}>
          <div className="tab-content" key={`multi_tracker_${category.id}`}>
            <MultipleTrackingCharts 
              key={`multi_tracker_${category}`} 
              title={category.name} 
              group={chosenGroup} 
              category={category} 
              chosenDefinitionId={preChosenDefinitionId === -1 ? null : preChosenDefinitionId}
              configurations={configurations}
              boundaries={chosenGroup ? chosenGroup.definitions : undefined} 
              dateRange={dateRange}
              userName={userName}
              duplicateViewFn={duplicateView}
            />
          </div>
        </Tab>)
      })

      

    return returnTabs;
  }

  return (
    <>
    <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
    {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
    <div className="container-fluid bg-light">
      {preChosenDefinitionId !== -1 && (
        <div className="row mb-2">
          <div className="col-sm-12 alert alert-info alert-dismissible fade show" role="alert">
            <button type="button" className="btn-close" data-bs-dismiss="alert" 
              onClick={handleDismissAlert} />
            You are viewing the marker: {preChosenDefinition.name}
          </div>
        </div>
      )}
      <div className="row">
        <nav className="navbar navbar-expand navbar-light">
          <div className="container-fluid">
            <ButtonGroup className="mr-2" aria-label="views">
              <DropdownButton as={ButtonGroup} key='views' title={chosenView ? chosenView.name : 'View'} id="dropdown-basic">
                <Dropdown.Item href="#" key='no_view' onClick={handleViewChange}>None</Dropdown.Item>
                  {views && views.map((view) => (<Dropdown.Item key={view.name} onClick={handleViewChange} value={view.name}>{view.name}</Dropdown.Item>))}
              </DropdownButton>
            </ButtonGroup>
            {interventions && interventions.length > 0 && (
              
              <ButtonGroup className="mr-2" aria-label="interventions" >
                <DropdownButton as={ButtonGroup} key='interventions' title={chosenIntervention.name ? chosenIntervention.name : 'Interventions'} id="dropdown-basic">
                  <Dropdown.Item 
                    key='no-intervention' 
                    onClick={handleInterventionChange()} 
                  >
                      None
                  </Dropdown.Item>
                  {interventions && interventions.map((intervention) => (
                  <Dropdown.Item 
                    key={intervention.uuid} 
                    onClick={handleInterventionChange(intervention.uuid)} 
                  >
                    {intervention.name}
                  </Dropdown.Item>
                  ))}
                </DropdownButton>
              </ButtonGroup>
            )}
            <ButtonGroup className="mr-2" aria-label="groups">
              <DropdownButton  as={ButtonGroup} key='groups'  title={chosenGroup ? chosenGroup.name : 'Group'} id="dropdown-basic">
                {groups && groups.map((group) => (<Dropdown.Item key={group.uuid} onClick={handleGroupChange} value={group.name}>{group.name}</Dropdown.Item>))}
              </DropdownButton>
            </ButtonGroup>
          </div>
        </nav>
      </div>
      <div className="row">
        <h5 onClick={(e) => setShowDates(!showDates)}>Dates...</h5>
        {showDates && (
          <>
          <div className="col" id="datepicker">
              <label htmlFor="fromDateInput">From Date</label>
              <input 
                type="date" 
                className="form-control" 
                id="fromDateInput" 
                placeholder='' 
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)} />
              
          </div>
          <div className="col"> 
            <label htmlFor="toDateInput">To Date</label>
            <input 
              type="date" 
              className="form-control" 
              id="toDateInput" 
              placeholder='' 
              value={toDate}
              onChange={(e) => setToDate(e.target.value)} />
          </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-md-12">
          <Tabs
            id="home_tabs"
            className="mb-3 navbar-light bg-light"
            fill
          >
            {chosenView && displayTabs() }
          </Tabs>
        </div>
      </div>
    </div>
    </>
  );
}
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, ReferenceArea  } from 'recharts';
import moment from 'moment';
import { useState, useEffect, useRef } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip'

import InsightsData from './insights-data.component';
import DefinitionSelector from './definition-selector.component';

import capitalizeFirstLetter from '../utils/capitalize-first-letter';
import LevelsProcessor from '../utils/levelsProcessor';
import EntriesSheet from './entries-sheet.component';

export default function TrackingChart({definition, 
  group, noGraphTitle, data, boundary, dateRange, 
  setErrorMessage, setSuccessMessage, setMergeData, updateGraphFn,
  originalGraph, extraData}) {

  const [yAxisMinMax, setYAxisMinMax] = useState({min: 0, max: 100});
  const [isEditEntriesMode, setIsEditEntriesMode] = useState(false);
  const [isUpdateNameMode, setIsUpdateNameMode] = useState(false);
  const [showUsedDefinitions, setShowUsedDefinitions] = useState(false)
  const [isFullScreenMode, setFullScreenMode] = useState(false);
  const [dataClustering, setDataClustering] = useState(0);
  const [localData, setLocalData] = useState([]);
  const [extraDataKeys, setExtraDataKeys] = useState([]);
  const [extraDataYAxisMinMax, setExtraDataYAxisMinMax] = useState({});
  const [graph, setGraph] = useState(originalGraph || {name: noGraphTitle});

  const isGraphMode = originalGraph ? true : false;

  const _getMinMax = (data, extraDataKeys) => {
    if (!extraDataKeys) {
      extraDataKeys = [];
    }
    let min, max;
    let extraDataKeysMinMax = {};
    for (const datum of data) {
      datum.value = parseFloat(datum.value);
      for (const extraDataKey of extraDataKeys) {
        extraDataKeysMinMax[extraDataKey] = {};
        datum[extraDataKey] = parseFloat(datum[extraDataKey]);
        if (isNaN(datum[extraDataKey])) {
          delete datum[extraDataKey];
        } 
      }

      if (isNaN(datum.value)) continue;

      if (!min) min = datum.value;
      if (!max) max = datum.value;
      for (const extraDataKey of extraDataKeys) {
        if (!extraDataKeysMinMax[extraDataKey].min) extraDataKeysMinMax[extraDataKey].min = datum[extraDataKey];
        if (!extraDataKeysMinMax[extraDataKey].max) extraDataKeysMinMax[extraDataKey].max = datum[extraDataKey];
      }
      

      if (min > datum.value) min = datum.value;
      if (min > datum.min) min = datum.min;

      if (max < datum.value) max = datum.value;
      if (max < datum.max) max = datum.max;

      for (const extraDataKey of extraDataKeys) {
        if (extraDataKeysMinMax[extraDataKey].min > datum.value) extraDataKeysMinMax[extraDataKey].min = datum.value;
        if (extraDataKeysMinMax[extraDataKey].max < datum.value) extraDataKeysMinMax[extraDataKey].max = datum.value;
      }
    }

    return [min, max, extraDataKeysMinMax]
  }

  const _clusterData = (data, dataClustering, extraDataKeys = []) => {
    if (dataClustering !== 0) {
      let clusterBy;
      switch (dataClustering) {
        case 1:
          clusterBy = 'day';
          break;
        case 2:
          clusterBy = 'week';
          break;
        case 3:
          clusterBy = 'month';
          break;
        default:
          clusterBy = 'day';
          break;
      }

      let localDataByDate = data.reduce((acc, datum) => {
        const date = moment(datum.date).startOf(clusterBy);
        if (!acc[date]) {
          acc[date] = datum;
          acc[date].value = parseFloat(datum.value);
          if (isNaN(acc[date].value)) {
            delete acc[date].value;
          }
          acc[date].originalDatum = [datum]
          for (const extraDataKey of extraDataKeys || []) {
            const extraValue = parseFloat(acc[date][extraDataKey]);
            if (!isNaN(extraValue)) {
              acc[date][`original_${extraDataKey}`] = []
            } else {
              acc[date][`original_${extraDataKey}`] = [extraValue];
            }
          }
        } else {
          acc[date].originalDatum.push(datum);
          if (datum.value) {
            acc[date].value = acc[date].value + parseFloat(datum.value)
          }
          for (const extraDataKey of extraDataKeys || []) {
            if (!isNaN(datum[extraDataKey])) {
              acc[date][extraDataKey] = acc[date][extraDataKey] + parseFloat(datum[extraDataKey])
              acc[date][`original_${extraDataKey}`].push(acc[date][extraDataKey]);
            }
          }
        }

        return acc;
      }, {});

      localDataByDate = Object.values(localDataByDate);
      localDataByDate.forEach((datum) => {
        datum.value = datum.value / datum.originalDatum.length;
        for (const extraDataKey of extraDataKeys || []) {
          // not sure this is accurate! the numbers can differ
          datum[extraDataKey] = datum[extraDataKey] / datum[`original_${extraDataKey}`].length;
        }
      })

      return localDataByDate;
    }
    return data;
  }

  useEffect(() => {
    let min, max, extraDataMinMax, localData = structuredClone(data);

    let localExtraDataKeys = [];

    if (extraData && Object.keys(extraData).length > 0) {
      localExtraDataKeys = Object.keys(extraData);
      for (const extraDataKey of localExtraDataKeys) {
        for (const datum of extraData[extraDataKey]) {
          datum.value = parseFloat(datum.value);
          if (isNaN(datum.value)) continue;
          const existingDatum = localData.find((ld) => ld.date === datum.date);

          if (existingDatum) {
            existingDatum[extraDataKey] = datum.value;
          } else {
            localData.push({
              date: datum.date,
              [extraDataKey]: datum.value
            })
          }
        }
      }

      localData.sort((a, b) => {
        return moment(a.date).isAfter(b.date) ? -1 : 1;
      } ).reverse();

      setExtraDataKeys(localExtraDataKeys);
    }

    localData = _clusterData(localData, dataClustering, localExtraDataKeys);
    [min, max, extraDataMinMax] = _getMinMax(localData, localExtraDataKeys);

    if (boundary && boundary.group_definitions.levels) {
      const levelsProcessor = new LevelsProcessor(boundary.group_definitions.levels);
      levelsProcessor.process();
      min = Math.min(levelsProcessor.min, min);
      max = Math.max(levelsProcessor.max, max);
    }

    let range = max-min;
    if (range === 0) {
      range = max / 2;
    }
    setYAxisMinMax({min: parseFloat(min) - (range*1.12), max: parseFloat(max) + (range*1.12)});
    if (extraData && Object.keys(extraData).length > 0) {
      setExtraDataYAxisMinMax(extraDataMinMax);
    }
    setLocalData(localData);
  }, [data, boundary, graph, dateRange, dataClustering, extraData])

  const formatXAxis = (tickItem) => { 
    return moment(tickItem).format('MMM Do YY') 
  }

  const onNewDefinitionToViewSelected = (definitionsSelected) => {
    if (definitionsSelected.length > 0) {
      setExtraDataKeys(definitionsSelected);
      if (setMergeData) setMergeData(definitionsSelected[0].id);
    }
  }

  return (
    <div className="col-md-12 mb-3">
      <ReactTooltip id="my-tooltip" />

      <div className="row">
        <div>
          <div>
          {isUpdateNameMode ? (
            <div className="input-group mb-3">
              <input 
                type="text" 
                className="form-control" 
                placeholder={graph.name} 
                aria-label="Graph name" 
                aria-describedby="Graph-name"
                onChange={(event) => setGraph({...graph, name: event.target.value})} />
            </div>
          ) : (
            <h4 style={{'display': 'inline'}}>
              {capitalizeFirstLetter(graph.name)}
            </h4>
          )}
          {graph.description && (
            <p>{graph.description}</p>
          )}
          {isGraphMode && !isUpdateNameMode && (
                <i 
                  className="bi bi-pen"
                  onClick={(e) => setIsUpdateNameMode(true)}
                >
                </i>)}
          </div>
          <div>
            <span className="tracking-char-title-icon"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Save graph"
              data-tooltip-place="top">
              {updateGraphFn && (
                <i 
                  className="bi bi-save-fill margin-right: 10px"
                  onClick={(e) => {updateGraphFn(graph)}}
                >
                </i>
              )}
              {!updateGraphFn && (
                <i 
                  className="bi bi-save margin-right: 10px"
                >
                </i>
              )}

            </span>
            <span className="tracking-char-title-icon"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Edit entries manually"
              data-tooltip-place="top">
              <i 
                className="bi bi-tools"
                onClick={(e) => setIsEditEntriesMode(!isEditEntriesMode)}
              >
              </i>
            </span>
            <span className="tracking-char-title-icon"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Set full screen mode"
              data-tooltip-place="top">
              <i
                className="bi bi-fullscreen mr-2"
                onClick={(e) => setFullScreenMode(!isFullScreenMode)}
              />
            </span>
            <span className="tracking-char-title-icon"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Cluster data daily"
              data-tooltip-place="top">
              <i
                className={`bi ${dataClustering === 1 ? 'bi-calendar-day-fill' : 'bi-calendar-day'} mr-2`}
                onClick={(e) => setDataClustering(dataClustering === 1 ? 0 : 1)}
              />
            </span>
            <span className="tracking-char-title-icon"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Cluster data weekly"
              data-tooltip-place="top">
              <i
                className={`bi ${dataClustering === 2 ? 'bi-calendar-week-fill' : 'bi-calendar-week'} mr-2`}
                onClick={(e) => setDataClustering(dataClustering === 2 ? 0 : 2)}
              />
            </span>
            <span className="tracking-char-title-icon"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Cluster data monthly"
            data-tooltip-place="top">
              <i
                className={`bi ${dataClustering === 3 ? 'bi-calendar-month-fill' : 'bi-calendar-month'} mr-2`}
                onClick={(e) => setDataClustering(dataClustering === 3 ? 0 : 3)}
              />
            </span>
            {setMergeData && (
              <span className="tracking-char-title-icon"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Merge this graph with another"
              data-tooltip-place="top">
                <i
                  className='bi bi-sign-merge-left-fill'
                  onClick={(e) => setMergeData()}
                />
              </span>
            )}
            {isGraphMode && (
              <div className="row mb-3">
              <div className="col form-check form-check-inline">
                <div className="form-check form-switch">
                  <input className="form-check-input" 
                    type="checkbox" 
                    id="useKnownDefinitions" 
                    onChange={(event) => setShowUsedDefinitions(event.target.checked)} />
                  <label className="form-check-label" htmlFor="useKnownDefinitions">Import definitions I already use into this graph</label>
                </div>
              </div>
              {showUsedDefinitions &&
                  <>
                    <DefinitionSelector
                      setFailureMessage={setErrorMessage} 
                      setSuccessMessage={setSuccessMessage}
                      onDefinitionsPicked={onNewDefinitionToViewSelected}
                      allowChooseAll={false}
                      allowFilter={true}
                    />
                  </>
                }
           </div>
          )} 
          </div>
        </div>
        
        {isEditEntriesMode ? (
          <div className="card col-md-12">
            <div className="card-body">
            <EntriesSheet 
              presetDefinition={definition}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              allowExtraActions={false}
            />
            </div>
          </div>
        ) : (
          <>
            <div className={`card col-md-${isFullScreenMode ? 12 : 6}`}>
              <LineChart width={isFullScreenMode ? 1400 : 600} height={600} data={localData}>
                <YAxis domain={[yAxisMinMax.min, yAxisMinMax.max]}
                  yAxisId="0"
                  type="number"
                  scale="linear"
                  orientation="left"
                  interval="preserveEnd"
                />
                <Line key={graph.name} 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#8884d8" 
                  yAxisId="0"
                  connectNulls
                  dot={localData.length > 50 ? false : true} 
                />
                
                {extraDataKeys && extraDataKeys.length > 0 && extraDataKeys.map((extraDataKey, index) => (
                  <> 
                    <YAxis domain={[extraDataYAxisMinMax[extraDataKey] ? extraDataYAxisMinMax[extraDataKey].min : 0, 
                      extraDataYAxisMinMax[extraDataKey] ? extraDataYAxisMinMax[extraDataKey].max : 100
                    ]}
                      orientation="right"
                      scale="linear"
                      yAxisId={extraDataKey}
                      interval="preserveEnd"
                      />
                    <Line key={extraDataKey} 
                      type="monotone" 
                      dataKey={extraDataKey} 
                      stroke="#82ca9d" 
                      connectNulls
                      dot={localData.length > 50 ? false : true} 
                      yAxisId={extraDataKey} />
                  
                  </>
                ))}
                {boundary && boundary.group_definitions ? (
                  <>
                  {
                    boundary.group_definitions.levels ? Object.keys(boundary.group_definitions.levels).map((levelKey, index) => (
                      <ReferenceLine 
                        key={levelKey}
                        label={boundary.group_definitions.levels[levelKey].label || levelKey} 
                        strokeWidth={2} 
                        y={boundary.group_definitions.levels[levelKey].value} 
                        stroke={boundary.group_definitions.levels[levelKey].color || 'Red'} 
                        strokeDasharray="3 3" />
                    )) : null
                  }
                </>)
                : null
                }
                {
                  dateRange ? (
                    <>
                    <ReferenceArea 
                      x1={dateRange.started_at} 
                      x2={dateRange.ended_at} 
                      y1={yAxisMinMax.min} 
                      y2={yAxisMinMax.max} 
                      // label="Intervention"
                      stroke="red" fillOpacity={0.2} />
                    </>
                  ) : null
                }
                <CartesianGrid stroke="#ccc" />
                
                <Tooltip
                  labelFormatter={(value, name, props) => {
                    return moment(value).format(moment.localeData().longDateFormat('L'));
                  }} />
                {/* <Legend /> */}
                <XAxis dataKey="date" tickFormatter={formatXAxis}/>
              </LineChart>
              <div className="card-body">
                <p className="card-text"></p>
              </div>
            </div>
            {!isFullScreenMode && (
            <div className="card col-md-6">
              <InsightsData definition={definition} group={group} data={localData} boundary={boundary} />
            </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
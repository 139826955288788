import { useState, useEffect } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Markdown from 'react-markdown'

import Alert from './alert.component';

import UserService from "../services/user.service";
import userApplicationsService from '../services/user-applications.service';
import applicationsService from "../services/application.service";
import { Link } from "react-router-dom";

export default function ApplicationsPage() {
  const [userApplications, setUserApplications] = useState([])
  const [allApplications, setAllApplications] = useState([])
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });


  useEffect(() => {
    Promise.all([
      UserService.getApplications(),
      applicationsService.getAll()
    ]).then(
      responses => {
        setUserApplications(responses[0].data.applications);
        setAllApplications(responses[1].data.applications)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [])

  const removeApp = (appName) => {
    hideMessage();
    setLoading(true);
    UserService.removeApplication(appName).then(
      response => {
        setUserApplications(response.data.applications)
        setLoading(false);
        setSuccessMessage(response.successMessage);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  const addApp = (appId) => {
    hideMessage();
    setLoading(true);
    userApplicationsService.add(appId).then(
      response => {
        setUserApplications(response.data.applications)
        setLoading(false);
        setSuccessMessage(response.message)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  const hideMessage = () => {
    setAlert(alert => ({...alert, 
      show: false,
    }));
  }

  const setFailureMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: message
      }));
    }
  }

  const setSuccessMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'success',
        alertTitle: 'Success',
        alertMessage: message
      }));
    }
  }

  return (
    <>
      <ReactTooltip id="applications-actions-tooltip" />
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />

      <div className="container-fluid">
        <table className="table">
          <thead className="table-light">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Published?</td>
              <td>Installed?</td>
              <td>Publisher</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {allApplications && userApplications && allApplications.map((application) => {
              const installed = !!(userApplications.find((userApplication) => userApplication.name === application.name))
            return (
            <tr>
              <td>
                <Link to={`/applications/${application.id}`}>
                  {application.name}
                </Link>
              </td>
              <td>
                <Markdown>
                  {application.description}
                </Markdown>
              </td>
              <td>
                {application.published ? 
                  <i className="bi bi-shield-check"></i> :
                  <i className="bi bi-shield"></i>  
                }
              </td>
              <td>
                { 
                installed ? 
                  <i className="bi bi-journal-check"></i> :
                  <i className="bi bi-journal"></i>  
                }
              </td>
              <td>
              {application.publisher && 
              (
                <Link to={`/users/${application.publisher.username}`}>{application.publisher.username}</Link>
              )
              }
              </td>
              <td className="col">
                {installed ? 
                  <button 
                    type="button" 
                    key={`add-button-${application.name}`} 
                    className="btn btn-primary" 
                    data-tooltip-id="applications-actions-tooltip"
                    data-tooltip-content="Remove application"
                    data-tooltip-place="top"
                    onClick={() => {removeApp(application.name)}}>
                      <i className="bi bi-file-minus"></i>  
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                  </button> :
                  <button 
                    type="button" 
                    key={`remove-button-${application.name}`} 
                    className="btn btn-primary" 
                    data-tooltip-id="applications-actions-tooltip"
                    data-tooltip-content="Add application"
                    data-tooltip-place="top"
                    onClick={() => {addApp(application.id)}}>
                      <i className="bi bi-file-plus"></i>   
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                  </button>}
                
              </td>
            </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </>
  )
}
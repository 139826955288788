import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Alert from './alert.component';

import userService from "../services/user.service";
import viewService from "../services/view.service";

export default function ViewsPage() {
  const [userViews, setUserViews] = useState([])
  const [allViews, setAllViews] = useState([])
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { viewUuid } = useParams();

  useEffect(() => {
    setLoading(true);

    Promise.all([
      userService.getViews(viewUuid),
      viewService.getAll()
    ]).then(
      responses => {
        setLoading(false);
        setUserViews(responses[0].data.views);
        setAllViews(responses[1].data.views)
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )}, [viewUuid])

  const removeView = (viewUuid) => {
    setAlert(alert => ({...alert, show: false}));
    setLoading(true);
    userService.removeView(viewUuid).then(
      response => {
        setUserViews(response.data.views)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )
  }

  const addView = (viewUuid) => {
    setAlert(alert => ({...alert, show: false}));
    setLoading(true);

    userService.addView(viewUuid).then(
      response => {
        setUserViews(response.data.views)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )
  }

  return (
    <>
      <ReactTooltip id="view-actions-tooltip" />
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
       <div className="container-fluid">
          <table class="table">
            <thead className="table-light">
              <tr>
                <th scope="col">View</th>
                <th scope="col">Description</th>
                <th scope="col">Publisher</th>
                <th scope="col">Installed?</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {allViews && userViews && allViews.map((view) => {
                const installed = !!(userViews.find((userView) => userView.name === view.name))
                return (
                <tr>
                  <td><Link to={`/views/${view.uuid}`} >{view.name}</Link></td>
                  <td><Link to={`/views/${view.uuid}`} >{view.description}</Link></td>
                  <td>
                    <Link to={`/users/${view.publisher.username}`}>{view.publisher.username}</Link>
                  </td>
                  <td>
                    { installed ?  
                      <i className="bi bi-journal-check"></i> :
                      <i className="bi bi-journal"></i> 
                    }
                  </td>
                  <td>
                    {installed ? 
                      <button 
                        type="button" 
                        key={`add-button-${view.name}`} 
                        className="btn btn-primary" 
                        data-tooltip-id="view-actions-tooltip"
                        data-tooltip-content="Remove view"
                        data-tooltip-place="top"
                        onClick={() => {removeView(view.uuid)}}>
                          <i className="bi bi-file-minus"></i> 
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                      </button> :
                      <button 
                        type="button" 
                        key={`remove-button-${view.name}`} 
                        className="btn btn-primary" 
                        data-tooltip-id="view-actions-tooltip"
                        data-tooltip-content="Add view"
                        data-tooltip-place="top"
                        onClick={() => {addView(view.uuid)}}>
                          <i className="bi bi-file-plus"></i> 
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                      </button>}
                      <a 
                        href={`/share/view/${view.uuid}`} 
                        className="btn btn-info" 
                        data-tooltip-id="view-actions-tooltip"
                        data-tooltip-content="Share view"
                        data-tooltip-place="top"
                        key={`share-button-${view.name}`}>
                          <i className="bi bi-share"></i> 
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                      </a>
                  </td>
                </tr>
                )
              })}
            </tbody>
          </table>
      <a className="btn btn-secondary" href={`/views/add`} role="button">Add View</a>
      </div>
    </>
  )
}
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Alert from './alert.component';

import categoriesService from "../services/categories.service";
import userService from "../services/user.service";
import userCategoriesService from '../services/user-categories.service';
import authService from '../services/auth.service';

export default function CategoriesPage() {
  const [loading, setLoading] = useState(false);
  const [userCategories, setUserCategories] = useState([])
  const [allCategories, setAllCategories] = useState([])
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  const currentUser = authService.getCurrentUser();

  useEffect(() => {
    Promise.all([
      userService.getCategories(),
      categoriesService.getAll()
    ]).then(
      responses => {
        setUserCategories(responses[0].data.categories);
        setAllCategories(responses[1].data.categories)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [])

  

  const removeCategory = (categoryId) => {
    hideMessage();
    setLoading(true);
    userCategoriesService.remove(categoryId).then(
      response => {
            
            setUserCategories(response.data.categories)
            setLoading(false);
            setSuccessMessage(response.successMessage);
      }, error => {
            setFailureMessage(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            );
            setLoading(false);
      }
    )
  }

  const deleteCategory = (categoryId) => {
    hideMessage();
    setLoading(true);
    categoriesService.destroy(categoryId).then(
      response => {
        userService.getCategories().then(
          response => {
            
            setUserCategories(response.data.categories)
            setLoading(false);
            setSuccessMessage(response.successMessage);
          },
          error => {
            setFailureMessage(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            );
            setLoading(false);
          })
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  const addCategory = (categoryId) => {
    hideMessage();
    setLoading(true);
    userCategoriesService.add(categoryId).then(
      response => {
        setUserCategories(response.data.categories)
        setLoading(false);
        setSuccessMessage(response.message)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  const hideMessage = () => {
    setAlert(alert => ({...alert, 
      show: false,
    }));
  }

  const setFailureMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: message
      }));
    }
  }

  const setSuccessMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'success',
        alertTitle: 'Success',
        alertMessage: message
      }));
    }
  };

  return (
    <>
      <ReactTooltip id="category-actions-tooltip" />
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />

      <div className="container-fluid">
        <table className="table">
          <thead className="table-light">
            <tr>
              <th>Category</th>
              <th>Description</th>
              <th>Publisher</th>
              <th>Installed?</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
      {allCategories && userCategories && allCategories.map((category) => {
        const installed = !!(userCategories.find((userCategory) => userCategory.name === category.name))
        return (
          <tr>
            <td><Link to={`/categories/${category.id}`} >{category.name}</Link></td>
            <td>{category.description}</td>
            <td>
              <Link to={`/users/${category.publisher.username}`}>{category.publisher.username}</Link>
            </td>
            <td>
              { installed ? 
                <i className="bi bi-journal-check"></i> :
                <i className="bi bi-journal"></i>  
              }
            </td>
            <td>
              {category.publisher_id === currentUser.id ? 
                <button 
                  type="button" 
                  key={`delete-button-${category.name}`} 
                  className="btn btn-primary" 
                  data-tooltip-id="category-actions-tooltip"
                  data-tooltip-content="Delete category"
                  data-tooltip-place="top"
                  onClick={(e) => {deleteCategory(category.id)}}>
                  <i className="bi bi-trash"></i>
                </button> 
                   : ""
              }
              {installed ? 
                <button 
                  type="button" 
                  key={`remove-button-${category.name}`} 
                  className="btn btn-primary" 
                  data-tooltip-id="category-actions-tooltip"
                  data-tooltip-content="Remove category"
                  data-tooltip-place="top"
                  onClick={() => {removeCategory(category.id)}}>
                    <i className="bi bi-file-minus"></i>  
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                </button> :
                <button 
                  type="button" 
                  key={`add-button-${category.name}`} 
                  className="btn btn-primary" 
                  data-tooltip-id="category-actions-tooltip"
                  data-tooltip-content="Add category"
                  data-tooltip-place="top"
                  onClick={() => {addCategory(category.id)}}>
                    <i className="bi bi-file-plus"></i>  
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                </button>
              }
              <a 
                href={`/share/category/${category.name}`} 
                className="btn btn-info" 
                data-tooltip-id="category-actions-tooltip"
                data-tooltip-content="Share category"
                data-tooltip-place="top"
                key={`share-button-${category.name}`}>
                  <i className="bi bi-share"></i>
                  {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                  )}
              </a>
            </td>
          </tr>
        )
      })}
      </tbody>
    </table>
      <a className="btn btn-secondary" href={`/categories/add`} role="button">Add Category</a>
      </div>
    </>
  )
}

import moment from "moment"

import { useState, useEffect } from 'react';
import TrackingChart from './tracking-chart.component';
import DefinitionPicker from "./definition-picker.component"
import DefinitionSelector from "./definition-selector.component"
import Alert from "./alert.component"
import Markdown from 'react-markdown'

import categoryEntriesService from "../services/category-entries.service";
import definitionEntriesService from "../services/definition-entries.service";
import authService  from "../services/auth.service";
import definitionsService from "../services/definitions.service";
import shareService from "../services/share.service";
import categoriesService from "../services/categories.service";
import graphsService from "../services/graphs.service";
import aiService from "../services/ai.service";

import addTitleToDefinition from "../utils/add-title-to-definition"

export default function MultipleTrackingCharts({title, 
    group, configurations, category, 
    chosenDefinitionId, boundaries, 
    userName, shareUuid, dateRange,
    duplicateViewFn}) {
  const [loading, setLoading] = useState(false)
  const [loadingAi, setLoadingAi] = useState(false)
  const [graphData, setGraphData] = useState({});
  const [definitionsMap, setDefinitionsMap] = useState({});
  const [displayUpdateDefinitionPicker, setDisplayUpdateDefinitionPicker] = useState('');
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });
  const [updatedDefinitionsRequest, setUpdatedDefinitionsRequest] = useState([]);
  const [sharedLink, setSharedLink] = useState('')
  const [mergedGraphs, setMergedGraphs] = useState([])
  const [mergedGraphsData, setMergedGraphsData] = useState({})
  const [showUsedDefinitions, setShowUsedDefinitions] = useState(false)
  const [categoryChangedLocally, setCategoryChangedLocally] = useState(false)
  const [userOwnCategory, setUserOwnCategory] = useState(false)
  const [aiResponseDate, setAiResponseDate] = useState('')
  const [aiResponseText, setAiResponseText] = useState('')
  const [aiSourceName, setAiSourceName] = useState('')
  const [showObservation, setShowObservation] = useState(true);


  const onErrorMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'danger',
      alertTitle: 'Error',
      alertMessage: message
    }));
  }

  const onSuccessMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'success',
      alertTitle: 'Success',
      alertMessage: message
    }));
  }

  const updateGraph = (graphData) => {
    setLoading(true);
    let action;

    let isUpdate = false;
    if (graphData.id) {
      isUpdate = true;
      action = graphsService.update(graphData.id, graphData);
    } else {
      action = graphsService.create(graphData);
    }
    action.then(
      response => {
        const newGraph = response.data.graph;
        if (!isUpdate) {
          newGraph.temporary_id = graphData.temporary_id;
          const [localMergedGraphs, localMergedGraphsData] = _newGraphToMergedGraphs(newGraph);
          
          setMergedGraphs(localMergedGraphs);
          setMergedGraphsData(localMergedGraphsData);
        }
        onSuccessMessage(response.data.message);
        setLoading(false);
        return true;
      }).catch((error) => {
        onErrorMessage(error.response.data.message);
        setLoading(false);
        return false;
      });
  }

  const mergeGraphData = async (key) => {
    let definition = category.definitions.find((definition) => definition.id === key);

    if (!definition) {
      try {
        const dataResponse = await definitionEntriesService.get(key)

        if (dataResponse.data.definitions) {
          definition = dataResponse.data.definitions[0];
        } else {
          setAlert(alert => ({...alert,
            show: true,
            alertType: 'danger',
            alertMessage: 'Definition not found'
          }));
          return; 
        }
      }
      catch (error) {
        setAlert(alert => ({...alert,
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&        
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
        return;
      }
    }

    const actualKey = definition ? definition.name : key;
    const newGraphName = 'New Graph'; // this is always creating a new graph but we might want to merge
    let localMergeGraphData = {...mergedGraphsData};

    const existingGraph = mergedGraphs.find((graph) => !graph.id); // for now merge without 'where' will go to the graph that is not saved yet

    let localGraph, localGraphId;
    
    if (existingGraph) {
      localGraph = {...existingGraph};
      if (!localGraph.definitions.some((definition) => definition.id === key)) {
        localGraph.definitions.push(definition);
      }
      setMergedGraphs([localGraph]);
      localGraphId = existingGraph.id || existingGraph.temporary_id;
    } else {
      localGraph = {
        name: newGraphName,
        temporary_id: Math.random().toString(36).substring(7), // until saved
        definitions: []
      };
      localGraphId = localGraph.temporary_id;
    }

    if (!localMergeGraphData[localGraphId]) {
      localMergeGraphData[localGraphId] = {};
    }

    if (localMergeGraphData[localGraphId][actualKey]) {
      localMergeGraphData[localGraphId][actualKey] = null;
      delete localMergeGraphData[localGraphId][actualKey];
      if (mergedGraphs.length > 0) {
        localGraph.definitions = localGraph.definitions.filter((definition) => definition.id !== key);
        setMergedGraphs([localGraph]);
      }
    } else {
      if (graphData[key]) {
        localMergeGraphData[localGraphId][actualKey] = graphData[key];
      } else if (definition.Entries) {
        let [localGraphData, localDefinitionsMap] = _definitionsToGraphData([definition]);
        localMergeGraphData[localGraphId][actualKey] = localGraphData[key];
      }
      
         
      localGraph.definitions.push(definition);
      setMergedGraphs(mergedGraphs.concat(localGraph))
    }
    setMergedGraphsData(localMergeGraphData);
    setCategoryChangedLocally(true);
  }

  const _newGraphToMergedGraphs = (newGraph) => {
    const localMergedGraphs = mergedGraphs.map((graph) => {
      if (graph.temporary_id && graph.temporary_id === newGraph.temporary_id) {
        if (!newGraph.definitions && graph.definitions) {
          newGraph.definitions = graph.definitions;
        }
        return newGraph;
      } else {
        return graph;
      }

    });
    let localMergedGraphsData = {...mergedGraphsData};
    localMergedGraphsData[newGraph.id] = mergedGraphsData[newGraph.temporary_id];
    delete localMergedGraphsData[newGraph.temporary_id];

    return [localMergedGraphs, localMergedGraphsData];
  };

  const _definitionsToGraphData = (definitions) => { 
    let localGraphData = {};
    let localDefinitionsMap = {};
    definitions.forEach((definition) => {
      let indexName;
      if (definition.rule_name) {
        indexName = definition.rule_name;
      } else {
        indexName = definition.id
      }

      if (!localGraphData[indexName]) {
        localGraphData[indexName] = [];
      }

      if (!localDefinitionsMap[indexName]) {
        localDefinitionsMap[indexName] = definition;
        addTitleToDefinition(localDefinitionsMap[indexName])
      }

      if (dateRange && (dateRange.started_at || dateRange.ended_at)) {
        if (dateRange.started_at)  {
          definition.Entries.push({date_taken: dateRange.started_at, values: []})
        }
        if (dateRange.ended_at)  {
          definition.Entries.push({date_taken: dateRange.ended_at, values: []})
        }
      }

      definition.Entries.sort((a, b) => {
        return moment(a.date_taken).isAfter(b.date_taken) ? -1 : 1;
      } ).reverse();
      for (const entry of definition.Entries) {
        const currentData = {name: indexName, date: entry.date_taken}
        currentData.value = entry.values.length ? entry.values[0].value : '';
        localGraphData[indexName].push(currentData);
      }
    })

    return [localGraphData, localDefinitionsMap];
  }

  const getSpecificDefinitionEntries = (definitionName) => {
    setLoading(true);
    definitionEntriesService.getAll([definitionName]).then(
      (response) => {
        const definitions = response.data.definitions;
        let [localGraphData, localDefinitionsMap] = _definitionsToGraphData(definitions);
        setGraphData({...localGraphData, ...graphData});
        setDefinitionsMap({...localDefinitionsMap, ...definitionsMap});
        setCategoryChangedLocally(true);
        setLoading(false);
      }
      ,(error) => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      })
  }

  const _getAIForDefinitions = (category) => {
    if (category.definitions.length === 0) return;
    if (!category.definitions.some((definition) => definition.Entries.length > 0)) return;

    setLoadingAi(true);

    aiService.create(
      category,
    ).then(
      response => {
        setLoadingAi(false);
        if (response.data.success === true) {
          const aiResponse = response.data.data;
          setAiResponseDate(aiResponse.request_date);
          setAiSourceName(aiResponse.ai_name || '');
          setAiResponseText(aiResponse.response_text || '');
        } else {
          setAiResponseText('No AI response');
        }
      },
      error => {
        setLoadingAi(false);
        if (error.status === 402) {
          setAiResponseText('No AI response - this is currently a paid service.');
        } else {
          setAlert(alert => ({...alert, 
            show: true,
            alertType: 'danger',
            alertMessage: (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }));
        }
      }
    )
    
  }

  useEffect(() => {
    if (!category.definitions.length && !!(category.graphs && !category.graphs.length)) return;
    let dataRequest;

    setLoading(true);
    setUserOwnCategory(category.publisher_id === JSON.parse(localStorage.getItem('user')).id);

    if (category.id) {
      if (userName) {
        dataRequest = categoryEntriesService.getAllByUserName(userName, category.id)
          .then((response) => response.data.category)
      } else if (shareUuid) {
        dataRequest = categoryEntriesService.getAllByShareUuid(shareUuid, category.id)
          .then((response) => response.data.category)
      } else {
        if (category.virtual_id === undefined) {
          dataRequest = categoryEntriesService.getAll(category.id, category.application_id)
            .then((response) => response.data.category)
        } else {
          dataRequest = categoryEntriesService.getAll(category.virtual_id, category.application_id)
            .then((response) => response.data.category)
        }
      }
    } else {
      const definitionNames = category.definitions.map((definition) => definition.name)
      if (definitionNames && definitionNames.length > 0) {
        if (userName) {
          dataRequest = definitionEntriesService.getAllByName(userName, {definitions_names: definitionNames})
            .then((response) => ({definitions: response.data.definitions}))
        } else {
          dataRequest = definitionEntriesService.getAll(definitionNames)
            .then((response) => ({definitions: response.data.definitions}))
        }
      } else {
        dataRequest = Promise.resolve({definitions: []});
      }
    }

    dataRequest.then(
      category  => {
        const {definitions, graphs} = category;
        if (definitions && definitions.length) {
          _getAIForDefinitions(category);
        }
        let [localGraphData, localDefinitionsMap] = _definitionsToGraphData(definitions);

        if (graphs && graphs.length) {
          setMergedGraphs(graphs);
          const localMergeGraphsData = {};
          for (const graph of graphs) {
            let [localGraphSpecificData, localGraphSpecificDefinitionsMap] = _definitionsToGraphData(graph.definitions);
            localDefinitionsMap = {...localDefinitionsMap, ...localGraphSpecificDefinitionsMap};
            localMergeGraphsData[graph.id] = localGraphSpecificData;
          }
          
          setMergedGraphsData(localMergeGraphsData);
        }

        setGraphData(localGraphData);
        setDefinitionsMap(localDefinitionsMap);
        setLoading(false);
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    );
  }, [category.definitions, category.graphs, category.id,category.publisher_id, category.virtual_id, category.application_id, 
      userName, shareUuid, dateRange]);

  const isRuleTrue = (rule) => {
    const localRule = JSON.parse(JSON.stringify(rule));
    let formula = localRule.formula;

    let safetyLength;
    const currentUser = authService.getCurrentUser();
    
    while (formula.includes('$') && (safetyLength === undefined || safetyLength !== formula.length)) {
      const variableStartPosition = formula.indexOf('$');
      const variablesNames = formula.substr(variableStartPosition+1).match(/^[a-zA-Z0-9_.]*/g)[0].split('.');
      let variableValue; 
      let parentData;
      switch (variablesNames[0].toLowerCase()) {
        case 'groupconfiguration':
          parentData = configurations.groupConfiguration.configuration;
          break;
        case 'profile':
          parentData = currentUser;
          break;
        case 'configuration':
          parentData = currentUser.configuration;
          break;
        default:
          console.log('unknown parent data')
          parentData = {};
      }

      for (const variableName of variablesNames) {
        if (variableName.toLowerCase() === variablesNames[0].toLowerCase()) continue;
        parentData = parentData[variableName]
      }
      variableValue = parentData;
      
      safetyLength = formula.length;
      formula = formula.replace(`$${variablesNames.join('.')}`, variableValue);
    }

    if (formula.toLowerCase() === 'false' || formula === '0') return false;
    
    return !!formula;
  }

  const findValueRule = (boundariesByRules) => {
    if (!configurations) return;
    for (const ruleName of Object.keys(boundariesByRules)) {
      const rule = group.group_definitions_rules.find((groupDefinitionRule) => groupDefinitionRule.name === ruleName);

      if (!rule) {
        continue;
      }

      if (isRuleTrue(rule)) {
        return rule;
      }
    }
  }

  const getMergedTrackingCharts = () => {
    const group = null; // collection of groups?
    const definition = null; // collection of definitions?
    const boundary = null;
    const key = 'merged';
    const dateRange = null;

    const mergedTrackingCharts = [];

    for (const graph of mergedGraphs) {

      const graphData = mergedGraphsData[graph.id || graph.temporary_id];

      const dynamicKey = Object.keys(graphData)[0];
      let {[dynamicKey]: firstData, ...restOfData} = graphData;

      // const title = `Merged Data - ${Object.keys(graph).join(', ')}`; // should change to list of titles

      mergedTrackingCharts.push(<TrackingChart 
        group={group} 
        definition={definition} 
        data={firstData || []} 
        boundary={boundary} 
        key={graph.id || graph.temporary_id || key} 
        dateRange={dateRange}
        setErrorMessage={onErrorMessage}
        setSuccessMessage={onSuccessMessage}
        setLoading={setLoading}
        setMergeData={mergeGraphData}
        extraData={restOfData}
        originalGraph={graph}
        updateGraphFn={updateGraph}
      />)
    }

    return mergedTrackingCharts;
  }
  
  const listTrackingChartByCategory = (category) => {
    let returnTrackingCharts = [];
    let graphDataKeys = Object.keys(graphData).map((key) => parseInt(key));

    let categoryDefinitionIds;
    if (chosenDefinitionId) {
      if (category.definitions.findIndex((definition) => definition.id === chosenDefinitionId) === -1) {
        return [];
      } else {
        categoryDefinitionIds = [chosenDefinitionId]
      }
    } else {
      categoryDefinitionIds = category.definitions.map((definition) => definition.id);
    }
    const categoryGraphDataKeys = graphDataKeys.filter((graphDataKey) => 
      categoryDefinitionIds.includes(graphDataKey) && graphData[graphDataKey].length > 0)

    if (Object.keys(mergedGraphsData).length > 0) {
      returnTrackingCharts.push(getMergedTrackingCharts());
    }

    returnTrackingCharts.push(...(categoryGraphDataKeys.map((key) => {
      const boundary = boundaries ? boundaries.find((boundary)=>boundary.id === key) : undefined;
      if (boundary && boundary.group_definitions.boundaries_by_rules) {
        const appliedRule = findValueRule(boundary.group_definitions.boundaries_by_rules);
        if (appliedRule) {
          const ruleBoundaries = boundary.group_definitions.boundaries_by_rules[appliedRule.name].levels;
          const localBoundaries = {...boundary.group_definitions.levels, ...ruleBoundaries};
          boundary.group_definitions.levels = localBoundaries;
        }
      }
      return (<TrackingChart 
        noGraphTitle={definitionsMap[key].title} 
        group={group} 
        definition={definitionsMap[key]} 
        data={graphData[key]} 
        boundary={boundary} 
        key={key} 
        dateRange={dateRange}
        setErrorMessage={onErrorMessage}
        setSuccessMessage={onSuccessMessage}
        setLoading={setLoading}
        setMergeData={() => mergeGraphData(key)}
      />)
    })))
    graphDataKeys = graphDataKeys.filter((graphDataKey) => !categoryGraphDataKeys.includes(graphDataKey));

    const emptyDataKeys = categoryDefinitionIds.filter((categoryDefinitionName) => !graphData[categoryDefinitionName] || !graphData[categoryDefinitionName].length)
    graphDataKeys = graphDataKeys.filter((graphDataKey) => graphData[graphDataKey].length);

    if (!chosenDefinitionId) {
      returnTrackingCharts.push(...(graphDataKeys.map((key) => 
        (<TrackingChart 
          noGraphTitle={definitionsMap[key].title} 
          group={group} 
          data={graphData[key]} 
          key={key} 
          dateRange={dateRange}
          setErrorMessage={onErrorMessage}
          setSuccessMessage={onSuccessMessage}
          setLoading={setLoading}
        />))))
    }

    if (chosenDefinitionId && emptyDataKeys.length > 0) {
      returnTrackingCharts.push(<div className='row' key='empty_data'>
        <div className="row">
          <div className="col-6" id="list-tab" role="tablist">
            <h4>The following definitions were needed for this category but you are missing them.</h4> 
            <span>Press on each if you believe you actually have this data under a different name</span>
            <div className="list-group">
              {emptyDataKeys.map((key) => 
                (
                  <button className='list-group-item list-group-item-action' key={key} 
                  onClick={(e) => setDisplayUpdateDefinitionPicker(key)}
                  >
                      Missing data on {definitionsMap[key].title}
                  </button>
                ))}
            </div>
          </div>
          <div className="col-6">
            <div id="nav-tabContent">
                { 
                displayUpdateDefinitionPicker && (<>
                  <DefinitionPicker 
                    defaultTitle={`Add Marker Alternative to ${definitionsMap[displayUpdateDefinitionPicker].title}`}
                    setErrorMessage={onErrorMessage} 
                    setSuccessMessage={onSuccessMessage} 
                    onDefinitionPicked={onUpdateDefinitionRequest}
                    onMeasurementUnitTypePicked={onUpdateDefinitionMeasurementUnitTypeRequest}
                  />
                  <button className="btn btn-primary" onClick={(e) => requestMerge(e, displayUpdateDefinitionPicker)}>Submit Request</button>
                </>
                )
                } 
                { displayUpdateDefinitionPicker && 
                  <DefinitionSelector
                  setErrorMessage={onErrorMessage} 
                  setSuccessMessage={onSuccessMessage}
                  onDefinitionsPicked={onUpdateDefinitionsForMergeSelected}
                  allowFilter={true}
                />
                }
            </div>
          </div>
        </div>
      </div>)
    }
    return returnTrackingCharts;
  }

  const onUpdateDefinitionRequest = (newDefinition) => {
    const localDefinition = [JSON.parse(JSON.stringify(newDefinition))];
    setUpdatedDefinitionsRequest(localDefinition)
  }

  const onUpdateDefinitionMeasurementUnitTypeRequest = (measurementUnitTypeName) => {
    const localDefinition = [JSON.parse(JSON.stringify(updatedDefinitionsRequest))];
    localDefinition[0].measurementUnitType = {
      name: measurementUnitTypeName
    }
    setUpdatedDefinitionsRequest(localDefinition)
  }

  const onUpdateDefinitionsForMergeSelected = (definitionsSelected) => {
    setUpdatedDefinitionsRequest(JSON.parse(JSON.stringify(definitionsSelected)))
  }

  const requestMerge = (e, definitionId) => {
    e.preventDefault();

    const definition = category.definitions.find((definition) => definition.id === definitionId);
    const currentUser = authService.getCurrentUser();

    let measurementUnitTypesMatch;

    if (!definition.measurement_unit_types || definition.measurement_unit_types.length === 0) {
      measurementUnitTypesMatch = !updatedDefinitionsRequest.measurement_unit_types || updatedDefinitionsRequest.measurement_unit_types.length === 0;
    } else {
      if (!updatedDefinitionsRequest.measurement_unit_types || updatedDefinitionsRequest.measurement_unit_types.length === 0) {
        measurementUnitTypesMatch = false;
      } else {
        measurementUnitTypesMatch = definition.measurement_unit_types[0].name === updatedDefinitionsRequest.measurement_unit_types[0].name;
      }
    }

    if (definition.name === updatedDefinitionsRequest.name && measurementUnitTypesMatch) {
          setAlert(alert => ({...alert, 
            show: true,
            alertType: 'danger',
            alertTitle: 'Error',
            alertMessage: 'You did not change anything'
          }));
        }

    if (currentUser && currentUser.username === 'liorsion') {
      setLoading(true);
      definitionsService.merge(definition, updatedDefinitionsRequest).then(
        response => {
          setAlert({
            show: true,
            alertType: 'success',
            alertTitle: 'Success',
            message: "Definition was updated"
          });
          setDisplayUpdateDefinitionPicker('');
          setLoading(false);
        },
        error => {
          setLoading(false);
          setAlert(alert => ({...alert, 
            show: true,
            alertType: 'danger',
            alertTitle: 'Error',
            alertMessage: (error.response &&
              error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          }));
        }
      );
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertMessage: 'For now you are not allowed to use this functionality. Please email support@yourhealthplatform.online for more details'
      }));
    }
  }

  const updateCategory = () => {

    setLoading(true);
    let graphsAction = Promise.resolve();
    // there are graphs here, we either got them in advance or we need to create them
    if (mergedGraphs.length > 0) {

      for (const mergedGraph of mergedGraphs) {
        if (mergedGraph.definitions.length > 0) {
          if (!mergedGraph.id) {
            // create or update?
              const newGraph = {
                name: Object.keys(mergedGraphsData).join(', '),
                definitions: mergedGraph.definitions
              };
      
              graphsAction = graphsService.create(newGraph).then(
                response => {
                  const newGraph = response.data.graph;
                  const [localMergedGraphs, localMergedGraphsData] = _newGraphToMergedGraphs(newGraph);
                  setMergedGraphs(localMergedGraphs);
                  setMergedGraphsData(localMergedGraphsData);
                  return localMergedGraphs;
                },
                error => {
                  setLoading(false);
                  onErrorMessage(
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString()
                  );
                  return false;
                }
              );
            } else {
              graphsAction = Promise.resolve(mergedGraphs);
              
            }
        }
      }
    }

  graphsAction.then(
      graphs => {
        if (graphs === false) {
          setLoading(false);
          return;
        }
        if (!userOwnCategory) {
          if (duplicateViewFn) {
            // after this need to duplicate category or copy it over and update it
            duplicateViewFn().then((
              newView => {
                // reload 

                onSuccessMessage('Category was updated successfully');
                setCategoryChangedLocally(false);
                setLoading(false);
              }
            ),
            error => {
              onErrorMessage(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
              );
              setLoading(false);
            });
          } else {
            setLoading(false);
            onErrorMessage('It is not possible to change category at this point');
          }
        } else {
          const newDefinitions = category.definitions;
          categoriesService.update(category.id, {definitions: newDefinitions, graphs: graphs}).then(
            response => {
              onSuccessMessage('Category was updated successfully');
              setCategoryChangedLocally(false);
              setLoading(false);
            },
            error => {
              setLoading(false);
              onErrorMessage(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
              );
            }
          )
        }
      }, 
      error => {
        setLoading(false);
        onErrorMessage(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        );
      });
  }

  const shareExternally = () => {
    setLoading(true);
    shareService.create('category', null, null, category.id, null, true).then(
      response => {
        setAlert({
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          message: "Category was shared successfully."
        });
        setLoading(false);
        setSharedLink(response.data.link);
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }));
      }
    );
  }

  const handleUseKnownDefinitionsChange = (event) => {
    setShowUsedDefinitions(event.target.checked)
  }

  const onNewDefinitionToViewSelected = (definitionsSelected) => {
    if (definitionsSelected.length > 0) {
      const definitionExists = category.definitions.some((definition) => definition.id === definitionsSelected[0].id);
      if (!definitionExists) {
        category.definitions.push(definitionsSelected[0]);
      }
      getSpecificDefinitionEntries(definitionsSelected[0].name);
      setShowUsedDefinitions(false)
    }
  }

  return (
    <>
    {loading && (
      <span className="spinner-border spinner-border-sm"></span>
    )}
    <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
    <div className="row mb-3">
      <div className="col">
        <h3 className="text-primary">{title ? title : 'Title'}</h3>
        {categoryChangedLocally && 
        <>
          Category changed locally
          {userOwnCategory && (<span> (note: this is your own category)</span>)}
          .
          <button type="button" className="btn btn-outline-secondary btn-sm me-4" onClick={updateCategory}>Save changes (Update category)</button>
       </> 
        }
        <button type="button" className="btn btn-outline-primary btn-sm me-4" onClick={shareExternally}>Share externally</button>
        <span>
          {sharedLink}
          {sharedLink && <i 
            className="bi bi-clipboard"
            style={{cursor: 'pointer'}}
            onClick={() => {navigator.clipboard.writeText(sharedLink)}}>
          </i>}
        </span>
      </div>
    </div>
    {loadingAi && (
      <div className="row mb-3">
        <span>Asking my AI consultant about these results...</span>
        <span className="spinner-border spinner-border-sm" />
      </div>
    )}
    {aiResponseText.length > 0 && (
      <div className="row mb-3">
        <div className="card">
          <div className="card-header">
            <div className="float-start">
              <b>AI observation</b> from {aiSourceName} 
            </div>
            <div className="float-end">
              <i 
                className={`bi ${showObservation ? 'bi-window-desktop' : 'bi-window-plus'}`}
                onClick={(e) => setShowObservation(!showObservation)}></i>
            </div>
          </div>
          {showObservation && (
            <>
          <div className="card-body">
            <blockquote className="blockquote mb-0">
              <Markdown>
              {aiResponseText}
              </Markdown>
            </blockquote>
          </div>
          <div className="card-footer text-muted">
            <span>The medical information on this site is provided as an information resource only, and is not to be used or relied on for any diagnostic or treatment purposes. This information does not create any patient-physician relationship, and should not be used as a substitute for professional diagnosis and treatment.
            </span>
            <br />
          {aiResponseDate}
          </div>
          </>
          )}
        </div>
      </div>
    )
    }
    <div className="row mb-3">
      <div className="col form-check form-check-inline">
        <div className="form-check form-switch">
          <input className="form-check-input" 
            type="checkbox" 
            id="useKnownDefinitions" 
            onChange={handleUseKnownDefinitionsChange} />
          <label className="form-check-label" htmlFor="useKnownDefinitions">Import definitions I already use to a new graph</label>
        </div>
      </div>
      {showUsedDefinitions &&
          <>
            <DefinitionSelector
              setFailureMessage={onErrorMessage} 
              setSuccessMessage={onSuccessMessage}
              onDefinitionsPicked={onNewDefinitionToViewSelected}
              allowChooseAll={false}
              allowFilter={true}
            />
          </>
        }
    </div>
    <div className="row mb-3">
    {
      Object.keys(graphData).length > 0 && 
      listTrackingChartByCategory(category)
    }
    </div>
    </>
  )
}
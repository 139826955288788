import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import userGraphsService from '../services/user-graphs.service';

export default function GraphsPage() {
  const [failureMessage, setFailureMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false);
  const [userGraphs, setUserGraphs] = useState([])

  useEffect(() => {
    userGraphsService.getAll().then(
      response => {
        setUserGraphs(response.data.graphs);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [])

  const deleteGraph = (graphId) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    userGraphsService.destroy(graphId).then(
      response => {
        setUserGraphs(response.data.graphs)
        setLoading(false);
        setSuccessMessage(response.message);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <div className="container-fluid">
        <div className="row">
        {userGraphs && userGraphs.map((graph) => {
          return (
            <div className="col-sm-2" key={`graph-${graph.id}`}>
              <div className="card" style={{width: '18rem'}}>
                <div className="card-body">
                  <h5 className="card-title">{graph.name}</h5>
                  <p className="card-text">{graph.description}</p>
                  <Link to={`/graphs/${graph.id}`} >{graph.name}</Link>
                </div>
              </div>
              
            </div>
          )
        })}
        </div>
      </div>
    </>
  )
}
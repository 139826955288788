import { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import DefinitionPicker from "./definition-picker.component"
import DefinitionSelector from "./definition-selector.component"
import GraphSelector from "./graph-selector.component"

import categoriesService from '../services/categories.service';
import definitionsService from '../services/definitions.service';
import usersService from '../services/users.service';

import addTitleToDefinition from '../utils/add-title-to-definition';

export default function CategoryPage() {
  const [category, setCategory] = useState({});
  const [newCategoryFlag, setNewCategoryFlag] = useState(false);
  const [published, setPublished] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addedMakersList, setAddedMarkersList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("")
  const [customMultipleDefinitionsText, setCustomMultipleDefinitionsText]= useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [measurementUnitTypeName, setMeasurementUnitTypeName] = useState('');
  const [allDefinitions, setAllDefinitions] = useState([])
  const [showUsedDefinitions, setShowUsedDefinitions] = useState('addCustomSingleDefinition')
  const [definitionPicked, setPickedDefinition] = useState({});
  const [replaceCustomMultipleDefinition, setReplaceCustomMultipleDefinition] = useState(false);
  const [customDefinitionsPicked, setCustomDefinitionsPicked] = useState([]);

  let navigate = useNavigate();
  let { categoryId } = useParams();
  let { userName } = useParams();

  const handleCategoryUpdateFromServer = (response) => {
    const localCategory = response.data.category;
    for (const definition of localCategory.definitions) {
      addTitleToDefinition(definition);
    }
    if (localCategory.graphs) {
      for (const graph of localCategory.graphs) {
        addTitleToDefinition(graph);
      }
    }
    setCategory(localCategory);
    setPublished(localCategory.published);
    setReadOnly(localCategory.published);
    setAddedMarkersList(localCategory.definitions);
  }

  useEffect(() => {
    if (!categoryId) {
      if (userName) {
        setReadOnly(true);
        setErrorMessage('Wrong URL. Please use the menu to navigate to the category you want to see.');
        return;
      }
      setNewCategoryFlag(true);
      setCategory({
        name: 'New Category',
        definitions: [],
        published: false,
      });
      return;
    };

    let action;
    if (userName) {
      action = usersService.getCategory(userName, categoryId)
    } else {
      action = categoriesService.get(categoryId)
    }

    action.then(
      response => {
        handleCategoryUpdateFromServer(response); 
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [categoryId, userName])

  useEffect(() => {
    definitionsService.getAll().then(
      response => {
        setAllDefinitions(response.data.definitions);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
    
  }, [])

  const handlePublishedClick = (event) => {
    event.preventDefault();
    setPublished(!published)
  }

  const handleUseKnownDefinitionsChange = (event) => {
    setShowUsedDefinitions(event.target.id);
  }

  const onAddMarkerPickerClicked = (event) => {
    event.preventDefault();
    const newDefinition = definitionPicked;
    addTitleToDefinition(newDefinition);

    if (!newDefinition.measurement_unit_types || !newDefinition.measurement_unit_types.length === 0) {
      newDefinition.measurement_unit_types = [{
        name: measurementUnitTypeName
      }]
    }

    const newAddedMarkersList = addedMakersList.concat(newDefinition)
    setPickedDefinition({});
    setMeasurementUnitTypeName('')

    setAddedMarkersList(newAddedMarkersList);
  }

  const handleSubmit= (e) => {
    e.preventDefault();

    setErrorMessage("")
    setSuccessMessage("")

    setLoading(true);

    let remoteAction;

    if (newCategoryFlag) {
      const newCategory = {
        name: category.name,
        ai_daily_days_back: category.ai_daily_days_back,
        published: published,
        definitions: addedMakersList
      }
      
      remoteAction = categoriesService.create(newCategory);
    } else {
      remoteAction = categoriesService.update(categoryId, {
        category: category,
        graphs: category.graphs, 
        published: published, 
        definitions: addedMakersList});
    }

    remoteAction.then(
      (response) => {
        if (response.data.success && response.data.message) {
          setSuccessMessage(response.data.message)
        }
        handleCategoryUpdateFromServer(response);
        setLoading(false);
        if (newCategoryFlag) {
          setNewCategoryFlag(false);
          navigate(`/categories/${response.data.category.id}`)
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setErrorMessage(resMessage);
        });
  }

  const handleDefinitionPicked = (pickedDefinitions) => {
    if (!pickedDefinitions) return;
    const newAddedMarkersList = JSON.parse(JSON.stringify(pickedDefinitions));
    setAddedMarkersList(newAddedMarkersList);
  }

  const handleCustomDefinitionPicked = (pickedDefinitions) => {
    if (!pickedDefinitions) return;
    setCustomDefinitionsPicked(pickedDefinitions);
  }

  const addAllCustomDefinitions = (e) => {
    e.preventDefault();
    definitionsService.bulkCreate(customDefinitionsPicked).then(
      response => {
        setSuccessMessage('Definitions added successfully');
        let combinedDefinitions = addedMakersList.concat(response.data.definitions);
        combinedDefinitions = combinedDefinitions.filter((singleDefinition, index) => (
          combinedDefinitions.findIndex((def) => def.id === singleDefinition.id) === index
        )).map((definition) => addTitleToDefinition(definition));
        setAddedMarkersList(combinedDefinitions);
        setReplaceCustomMultipleDefinition(false);
        setCustomMultipleDefinitionsText('');
      }, error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
      
    )
  }

  const removeGraph = (graphId, graphName) => {
    const localGraphsPicked = category.graphs.filter((graph) => graph.id !== graphId);
    categoriesService.update(categoryId, {graphs: localGraphsPicked}).then(
      response => {
        setSuccessMessage(response.data.message);
        handleCategoryUpdateFromServer(response);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
  }

  const removeDefinition = (definitionId, definitionName) => {
    let action;
    const localDefinitionsPicked = addedMakersList.filter((def) => def.id !== definitionId);
    action = categoriesService.update(categoryId, {definitions: localDefinitionsPicked})
    action.then(
      response => {
        setSuccessMessage(response.data.message);
        handleCategoryUpdateFromServer(response);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
  }

  return (
    <>
      {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      {readOnly && <div className="alert alert-info">This category is already published and so is in read only mode</div>}
      {(category.id || category.name) &&
         (
          <div key={category.name}>
            <h2>{category.name}</h2>
            <form onSubmit={handleSubmit}>

              <div className="mb-3">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  key="name"
                  value={category.name}
                  onChange={(e) => setCategory({...category, name: e.target.value})}
                  readOnly={readOnly}
                  autoFocus
                />
              </div>

              <div className="mb-3">
                <label htmlFor="name">AI Daily Days Back To Look At</label>
                <input
                  type="number"
                  className="form-control"
                  name="ai_daily_days_back"
                  key="ai_daily_days_back"
                  value={category.ai_daily_days_back}
                  onChange={(e) => setCategory({...category, ai_daily_days_back: e.target.value})}
                  readOnly={readOnly}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="name">AI Daily Prompt</label>
                <input
                  type="string"
                  className="form-control"
                  name="ai_daily_prompt"
                  key="ai_daily_prompt"
                  value={category.ai_daily_prompt}
                  onChange={(e) => setCategory({...category, ai_daily_prompt: e.target.value})}
                  readOnly={readOnly}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="name">AI Daily Time Of Day</label>
                <input
                  type="time" 
                  step="3600000"
                  className="form-control"
                  name="ai_daily_hour"
                  key="ai_daily_hour"
                  value={category.ai_daily_hour}
                  onChange={(e) => setCategory({...category, ai_daily_hour: e.target.value})}
                  readOnly={readOnly}
                />
              </div>

              <div className="mb-3">
                <div className="bg-white border-bottom-0 h4 font-weight-light">Definitions & Graphs</div>
                <ul className="list-group" name="definitions">
                  {addedMakersList.map((marker)=>(
                    <li key={`list-group-item-${marker.id}`} className="list-group-item">
                      {marker.title}
                      <i className="bi bi-trash" onClick={(e) => {removeDefinition(marker.id, marker.name)}}></i>
                    </li>
                    ))}
                  {category.graphs && category.graphs.map((graph)=>(
                    <li key={`list-group-item-${graph.id}`} className="list-group-item">
                      {graph.title}
                      <i className="bi bi-trash" onClick={(e) => {removeGraph(graph.id, graph.name)}}></i>
                    </li>
                  ))}
                </ul>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="addCustomSingleDefinition" name="chooseHowToAddDefinitions"  
                    onChange={handleUseKnownDefinitionsChange} 
                    checked={showUsedDefinitions==='addCustomSingleDefinition'} />
                  <label className="form-check-label" htmlFor="addCustomSingleDefinition">Add Definition</label>
                </div>
                <div className="form-check form-check-inline" onChange={handleUseKnownDefinitionsChange}>
                  <input className="form-check-input" type="radio" id="useKnownDefinitions" name="chooseHowToAddDefinitions" onChange={handleUseKnownDefinitionsChange} 
                    checked={showUsedDefinitions==='useKnownDefinitions'}
                  />
                  <label className="form-check-label" htmlFor="useKnownDefinitions">Import definitions I already use</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="addCustomMultipleDefinition" name="chooseHowToAddDefinitions" onChange={handleUseKnownDefinitionsChange} 
                    checked={showUsedDefinitions==='addCustomMultipleDefinition'}
                    />
                  <label className="form-check-label" htmlFor="addCustomMultipleDefinition">Add Multiple Definitions</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="addGraphs" name="chooseHowToAddDefinitions" onChange={handleUseKnownDefinitionsChange} 
                    checked={showUsedDefinitions==='addGraphs'}
                    />
                  <label className="form-check-label" htmlFor="addGraph">Add Graphs</label>
                </div>
              </div>

              {!readOnly && showUsedDefinitions==='addCustomMultipleDefinition' &&
                <div className="mb-3">
                  {!replaceCustomMultipleDefinition && 
                  <>
                    <textarea 
                      className="form-control" 
                      rows="3" 
                      placeholder="Add a definition"
                      value={customMultipleDefinitionsText}
                      onChange={(e) => setCustomMultipleDefinitionsText(e.target.value)}>
                    </textarea>
                    <button className="btn btn-secondary" onClick={(e) => {setReplaceCustomMultipleDefinition(true)}}>Process</button>
                  </>

                  }
                  {replaceCustomMultipleDefinition && 
                    <>
                      <DefinitionSelector
                        setErrorMessage={setErrorMessage} 
                        setSuccessMessage={setSuccessMessage}
                        onDefinitionsPicked={handleCustomDefinitionPicked}
                        initialValue={addedMakersList}
                        entriesDefinitions={customMultipleDefinitionsText.split('\n').map((definition) => ({name: definition.trim()}))}
                        allowFilter={true}
                      />
                      <button className="btn btn-secondary" onClick={addAllCustomDefinitions}>Add All Markers</button>
                    </>
                  }
                </div>
              }
            
            {!readOnly && showUsedDefinitions==='useKnownDefinitions' &&
              <>
                <DefinitionSelector
                  setErrorMessage={setErrorMessage} 
                  setSuccessMessage={setSuccessMessage}
                  onDefinitionsPicked={handleDefinitionPicked}
                  initialValue={addedMakersList}
                />
              
                </>
            }

            {!readOnly && showUsedDefinitions==='addGraphs' &&
            <>
              <GraphSelector
                  setErrorMessage={setErrorMessage} 
                  setSuccessMessage={setSuccessMessage}
                  onGraphsPicked={(graphsPicked) => {setCategory({...category, graphs: graphsPicked})}}
                  initialValue={category.graphs}
                />
            </>
            }

            {!readOnly && showUsedDefinitions==='addCustomSingleDefinition'  && allDefinitions && 
              <div className='col-xs-12 col-sm-12'>
                <DefinitionPicker 
                  allDefinitionsToPickFrom={allDefinitions}
                  setErrorMessage={setErrorMessage} 
                  setSuccessMessage={setSuccessMessage} 
                  onDefinitionPicked={newDefinition => setPickedDefinition(newDefinition)}  
                  onMeasurementUnitTypePicked={newName => setMeasurementUnitTypeName(newName)}
                  definitionValue={definitionPicked.name}
                  unitMeasurementValue={measurementUnitTypeName}
                />
                <button className="btn btn-secondary" onClick={onAddMarkerPickerClicked}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                    Add Marker
                </button>
              </div>
              }

              <div className="form-check">
                <input className="form-check-input" 
                  name="published" 
                  type="checkbox" 
                  checked={published}
                  onClick={handlePublishedClick} 
                  onChange={handlePublishedClick}
                  disabled={readOnly} />
                <label htmlFor="published">Published</label>
              </div>

              <div className="mb-3">
                <button
                  className="btn btn-primary btn-block"
                  disabled={loading || readOnly}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Submit</span>
                </button>
              </div>
            </form>
          </div>)}
    </>
  )
}
import { useState, useEffect } from 'react';

import SearchItemsList from "./search-items-list";

import definitionsService from '../services/definitions.service';
import measurementUnitTypesService from '../services/measurement-unit-types.service';


export default function DefinitionPicker({
  defaultTitle = "Add Marker", 
  definitionValue = '', 
  unitMeasurementValue, 
  allDefinitionsToPickFrom, 
  setErrorMessage, 
  setSuccessMessage, 
  onDefinitionPicked, 
  onMeasurementUnitTypePicked}) {

  const [definitionName, setDefinitionName] = useState('');
  const [title, setTitle] = useState(defaultTitle);
  const [definitionsSearchItems, setDefinitionsSearchItems] = useState([])
  const [cursor, setCursor] = useState(0)
  const [allMeasurementTypes, setAllMeasurementTypes] = useState([])
  const [measurementUnitTypeName, setMeasurementUnitTypeName] = useState('');
  const [measurementUnitTypesSearchItems, setMeasurementUnitTypesSearchItems] = useState([])
  const [measurementUnitTypeCursor, setMeasurementUnitTypeCursor] = useState(0)
  const [allDefinitions, setAllDefinitions] = useState([])

  useEffect(() => {
    setDefinitionName(definitionValue);
  }, [definitionValue]);

  useEffect(() => {
    setMeasurementUnitTypeName(unitMeasurementValue);
  }, [unitMeasurementValue]);

  // needed for refresh
  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  useEffect(() => {
    let requestData;
    if (allDefinitionsToPickFrom && allDefinitionsToPickFrom.length > 0) {
      requestData = [
        Promise.resolve({data: {definitions: allDefinitionsToPickFrom}}),
        Promise.resolve({data: {measurement_unit_types: allDefinitionsToPickFrom.map((definitionsToPickFrom) => definitionsToPickFrom.measurement_unit_types).flat()}}),
      ]
    } else {
      requestData = [definitionsService.getAll(), measurementUnitTypesService.getNames()];
    }
    
    Promise.all(requestData).then(
      responses => {
        const localAllDefinitions = responses[0].data.definitions.map((definition) => ({...definition,
          name: definition.name,
          title: `${definition.name} (${definition.measurement_unit_types.map((measurementUnitType) => measurementUnitType.name).join(", ")})`,
        }));
        setAllDefinitions(localAllDefinitions);
        setAllMeasurementTypes(responses[1].data.measurement_unit_types);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
    
  }, [])

  const setAutoComplete = (allNames, setSearchItems, setResult, setValue) => {
    return (e) => {
      setErrorMessage("")
      setSuccessMessage("")
      let text = e.target.value;
      if (text === '') {
        setSearchItems(allNames)
      }
      if (allNames) {
        const data = allNames.filter((searchedName) => searchedName.name.toLowerCase().includes(text.toLowerCase()))
        setSearchItems(data || [] );
        setResult(text);
      } else {
        setResult(text);
      }
    }
  }

  const setHandleKeyup = (setSearchItems) => {
    return (evt) => {
      // event code 27 means escape key
      if (evt.keyCode === 27) {
          setDefinitionsSearchItems([]);
          return false;
      }
    }
  }

  const setHandleKeydown = (cursor, setCursor, setResult) => {
    return (evt) => {
      // arrow up/down button should select next/previous list element
      if (evt.keyCode === 38 && cursor > 0) {
        setCursor(cursor - 1);
      } else if (evt.keyCode === 40 && cursor < definitionsSearchItems.length - 1) {
        setCursor(cursor + 1);
      }

      if (evt.keyCode === 13) {
        evt.preventDefault();
        let currentItem = definitionsSearchItems[cursor];
        if (currentItem !== undefined) {
          // selectDefinition(currentItem);
          setResult(currentItem);
        }
      }

      if (evt.keyCode === 8) {
        setResult("");
      }
    }
  }

  const selectDefinition = (selectedItem) => {
    if (onDefinitionPicked) {
      onDefinitionPicked(selectedItem)
    }
    definitionValue = selectedItem.name;
    setDefinitionName(selectedItem.name);
    if (selectedItem.measurement_unit_types && selectedItem.measurement_unit_types.length) {
      setMeasurementUnitTypeName(selectedItem.measurement_unit_types[0].name)
      if (onMeasurementUnitTypePicked) onMeasurementUnitTypePicked(selectedItem.measurement_unit_types[0].name)
      unitMeasurementValue = selectedItem.measurement_unit_types[0].name;
      setMeasurementUnitTypesSearchItems(selectedItem.measurement_unit_types)
    }
    setDefinitionsSearchItems([]);
  }

  const selectDefinitionName = (definitionName) => {
    if (onDefinitionPicked) {
      onDefinitionPicked({name: definitionName})
    }
    definitionValue = definitionName;
    setDefinitionName(definitionName);
  }

  const selectMeasurementUnitType = (selectedMeasurementUnitType) => {
    setMeasurementUnitTypeName(selectedMeasurementUnitType.name)
    if (onMeasurementUnitTypePicked) onMeasurementUnitTypePicked(selectedMeasurementUnitType.name)
    unitMeasurementValue=selectedMeasurementUnitType.name;
    setMeasurementUnitTypesSearchItems([])
  }

  const selectMeasurementUnitTypeName = (measurementUnitTypeName) => {
    setMeasurementUnitTypeName(measurementUnitTypeName)
    if (onMeasurementUnitTypePicked) onMeasurementUnitTypePicked(measurementUnitTypeName)
    unitMeasurementValue=measurementUnitTypeName;
  }

  return (
    <div>
      <div className="bg-white border-bottom-0 h4 font-weight-light">{title}</div>
      <div className="col-xs-3 text-right">
        <label htmlFor="definition_name">Marker</label>
      </div>
      <div className="col-xs-3 text-right">
        <input
          type="text"
          className="form-control"
          name="definition_name"
          onChange={setAutoComplete(allDefinitions, setDefinitionsSearchItems, selectDefinitionName)}
          onKeyUp={setHandleKeyup(setDefinitionsSearchItems)}
          onKeyDown={setHandleKeydown(cursor, setCursor, selectDefinition) }
          value={definitionName}
        />
      </div>
      <div className="col-xs-3 text-right">
        {definitionsSearchItems.length > 0 && (
          <SearchItemsList
              searchItems={definitionsSearchItems}
              cursor={cursor}
              selectItem={selectDefinition}
        />)}
      </div>
      {definitionName && (
        <>
          <div className="col-xs-3 text-right">
            <label htmlFor="measurement_unit_type">Unit Type</label>
          </div>
          <div className="col-xs-3 text-right">
            <input
              type="text"
              className="form-control"
              name="measurement_unit_type"
              onChange={setAutoComplete(allMeasurementTypes, setMeasurementUnitTypesSearchItems, selectMeasurementUnitTypeName)}
              onKeyUp={setHandleKeyup(setMeasurementUnitTypesSearchItems)}
              onKeyDown={setHandleKeydown(measurementUnitTypeCursor, setMeasurementUnitTypeCursor, selectMeasurementUnitType) }
              value={measurementUnitTypeName}
            />
          </div>
          <div className="col-xs-3 text-right">
            {definitionsSearchItems.length > 0 && (
              <SearchItemsList
              searchItems={measurementUnitTypesSearchItems}
              cursor={measurementUnitTypeCursor}
              selectItem={selectMeasurementUnitType}
            />)}
          </div>
        </>
      )}
   </div>
  )
}
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Alert from './alert.component';

import UserService from "../services/user.service";
import groupService from "../services/group.service";
import authService from '../services/auth.service';

export default function GroupsPage() {
  const [userGroups, setUserGroups] = useState([])
  const [allGroups, setAllGroups] = useState([])
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { groupName } = useParams();
  const currentUser = authService.getCurrentUser();

  useEffect(() => {
    Promise.all([
      UserService.getGroups(groupName),
      groupService.getAll()
    ]).then(
      responses => {
        setUserGroups(responses[0].data.groups);
        setAllGroups(responses[1].data.groups)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [groupName])

  const removeGroup = (groupUuid) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    UserService.removeGroup(groupUuid).then(
      response => {
        if (response.data.groups) {
          setUserGroups(response.data.groups)
        }
        setLoading(false);
        setSuccessMessage(response.data.message);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  const addGroup = (groupUuid) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    UserService.addGroup(groupUuid).then(
      response => {
        setUserGroups(response.data.groups)
        setLoading(false);
        setSuccessMessage(response.message)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  const hideMessage = () => {
    setAlert(alert => ({...alert, 
      show: false,
    }));
  }

  const setFailureMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: message
      }));
    }
  }

  const setSuccessMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'success',
        alertTitle: 'Success',
        alertMessage: message
      }));
    }
  };

  return (
    <>
      <ReactTooltip id="group-actions-tooltip" />
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <div className="container-fluid">
        <table className="table">
          <thead className="table-light">
            <tr>
              <th>Group</th>
              <th>Description</th>
              <th>Publisher</th>
              <th>Installed?</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
      {allGroups && userGroups && allGroups.map((group) => {
        const installed = !!(userGroups.find((userGroup) => userGroup.name === group.name && userGroup.users && userGroup.users.length > 0 && 
          userGroup.users[0].username === currentUser.username))
        return (
          <tr key={group.uuid || group.id}>
            <td><Link to={`/groups/${group.uuid}`} >{group.name}</Link></td>
            <td><Link to={`/views/${group.uuid}`} >{group.description}</Link></td>
            <td>
              <Link to={`/users/${group.publisher.username}`}>{group.publisher.username}</Link>
            </td>
            <td>
              { installed ? 
                <i className="bi bi-journal-check"></i> :
                <i className="bi bi-journal"></i>  
              }
            </td>
            <td>
              {installed ? 
              <button 
                type="button" 
                key={`add-button-${group.uuid}`} 
                className="btn btn-primary" 
                data-tooltip-id="group-actions-tooltip"
                data-tooltip-content="Remove group"
                data-tooltip-place="top"
                onClick={() => {removeGroup(group.uuid)}}>
                  <i className="bi bi-file-minus"></i> 
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
              </button> :
              <button 
                type="button" 
                key={`remove-button-${group.uuid}`} 
                className="btn btn-primary" 
                data-tooltip-id="group-actions-tooltip"
                data-tooltip-content="Remove group"
                data-tooltip-place="top"
                onClick={() => {addGroup(group.uuid)}}>
                  <i className="bi bi-file-plus"></i> 
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
              </button>}
              <a 
                href={`/share/group/${group.uuid}`} 
                className="btn btn-info" 
                data-tooltip-id="group-actions-tooltip"
                data-tooltip-content="Share group"
                data-tooltip-place="top"
                key={`share-button-${group.uuid}`}>
                  <i className="bi bi-share"></i>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
              </a>
          </td>
        </tr>
        )
      })}
          </tbody>
        </table>
        <a className="btn btn-secondary" href={`/groups/add`} role="button">Add Group</a>
      </div>
      
    </>
  )
}
import api from '../interceptors/auth.interceptor';

class AiService {
  // getAll() {
  //   return api.get
  // }

  getDaily() {
    return api.get(process.env.REACT_APP_API_URL + '/api/ai/daily/');
  }

  create(
    category,
  ) {
    const {definitions: _, ...categoryWithoutDefinitions} = category;
    return api.post(process.env.REACT_APP_API_URL + '/api/ai/', {
      category: categoryWithoutDefinitions
    });
  }
}

const aiService = new AiService();
export default aiService;
import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import authService from "../services/auth.service";
import Alert from "./alert.component";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onResendVerificationRequested = this.onResendVerificationRequested.bind(this);
    this.onPasswordReminderRequested = this.onPasswordReminderRequested.bind(this)

    const lastMessage = localStorage.getItem("last-message");
    if (lastMessage) {
      localStorage.removeItem("last-message");
    }

    this.state = {
      username: "",
      password: "",
      loading: false,
      allowReverify: false,
      message: lastMessage || "",
      alert: {
        alertType: 'success',
        alertTitle: 'Success',
        alertMessage: '',
        show: false
      },
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onPasswordReminderRequested(e) {
    e.preventDefault();
    this.setState({
      loading: true
    });

    authService.requestPasswordReset(this.state.username).then(
      response => {
        this.setState({
          message: '',
          loading: false,
          alert: {...alert, 
            show: true,
            alertType: 'success',
            alertTitle: 'Success',
            alertMessage: response.data.message
          }
        });
      },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
            alert: {...alert, 
              show: true,
              alertType: 'danger',
              alertTitle: 'Error',
              alertMessage: resMessage
            }
          });
        }
    );
  }

  onResendVerificationRequested(e) {
    e.preventDefault();
    this.setState({
      allowReverify: false
    });

    authService.resendVerification(this.state.username, this.state.password).then(
      response => {
        this.setState({
          loading: false,
          alert: {...alert, 
            show: true,
            alertType: 'success',
            alertTitle: 'Success',
            alertMessage: response.data.message
          }
        });
      },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

       
          this.setState({
            loading: false,
            message: resMessage,
            allowReverify: error.response.data.allow_reverify,
            alert: {...alert, 
              show: true,
              alertType: 'danger',
              alertTitle: 'Error',
              alertMessage: resMessage
            }
          });
        }
    );
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.router.navigate("/user");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
            allowReverify: error.response && error.response.data ? error.response.data.allow_reverify : false
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <Alert variant={this.state.alert.alertType} title={this.state.alert.alertTitle} text={this.state.alert.alertMessage} position="top-end" initialShow={this.state.alert.show} />
        <div className="card card-container text-center mx-auto bg-primary" Style="background-color: white !important">
          <div className="card-title">
            Login
          </div>
          <div className="card-body">
            <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card"
            />

            <Form
              onSubmit={this.handleLogin}
              ref={c => {
                this.form = c;
              }}
            >
              <div className="mb-3">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  validations={[required]}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validations={[required]}
                />
              </div>

              <div className="mb-3">
                <button
                  className="btn btn-primary btn-block mr-1"
                  disabled={this.state.loading}
                >
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>
                <a className="btn btn-secondary float-end" href="/register">Register</a>
                
              </div>
              <div>
                <button className="btn btn-link" onClick={this.onPasswordReminderRequested}>Forgot my password</button>
              </div>

              {this.state.message && (
                <div className="mb-3">
                  <div className="alert alert-danger" role="alert">
                    {this.state.message}
                    {this.state.allowReverify && <button className="btn btn-primary btn-block" onClick={this.onResendVerificationRequested}>Resend Verification Email</button>}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={c => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login)
import api from '../interceptors/auth.interceptor';

class GroupService {
  getAll() {
    return api.get(process.env.REACT_APP_API_URL + '/api/groups');
  }

  get(groupUuid) {
    return api.get(process.env.REACT_APP_API_URL + `/api/groups/${groupUuid}`);
  }

  getById(groupId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/groups/ids/${groupId}`);
  }

  duplicateGroup(groupUuid) {
    return api.post(process.env.REACT_APP_API_URL + `/api/groups/${groupUuid}/duplicate`);
  }

  update(groupUuid, groupData) {
    return api.post(process.env.REACT_APP_API_URL + `/api/groups/${groupUuid}`, {group: groupData});
  }

  create(groupData) {
    return api.post(process.env.REACT_APP_API_URL + '/api/groups', {group: groupData});
  }

  delete(groupUuid) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/groups/${groupUuid}`);
  }

  createRule(groupId, ruleData) {
    return api.post(process.env.REACT_APP_API_URL + `/api/groups/${groupId}/rules`, {rule: ruleData});
  }

  updateRule(groupId, ruleData) {
    return api.put(process.env.REACT_APP_API_URL + `/api/groups/${groupId}/rules`, {rule: ruleData});
  }

  bulkCreateInitialBoundaries(groupId, definitions) {
    return api.post(process.env.REACT_APP_API_URL + `/api/groups/${groupId}/boundaries/init`, {definitions: definitions});
  }
}

const groupService = new GroupService();
export default groupService;
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Alert from './alert.component';

import userService from "../services/user.service";
import userInterventionService from "../services/user-intervention.service";

export default function InterventiosPage() {
  const [userInterventions, setUserInterventions] = useState([])
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { interventionUuid } = useParams();

  useEffect(() => {
    setLoading(true);

    userInterventionService.getAll().then(
      response => {
        setLoading(false);
        setUserInterventions(response.data.interventions);
      },
      error => {
        setLoading(false);
        setFailureMessage((error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        );
      }
    )}, [interventionUuid])

  const removeIntervention = (interventionUuid) => {
    hideMessage();
    setLoading(true);
    userService.removeIntervention(interventionUuid).then(
      response => {
        setUserInterventions(response.data.interventions)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setLoading(false);
        setFailureMessage((error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        );
      }
    )
  }

  const addIntervention = (interventionUuid) => {
    hideMessage();
    setLoading(true);

    userService.addIntervention(interventionUuid).then(
      response => {
        setUserInterventions(response.data.interventions)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setLoading(false);
        setFailureMessage((error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        );
      
      }
    )
  }

  const hideMessage = () => {
    setAlert(alert => ({...alert, 
      show: false,
    }));
  }

  const setFailureMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: message
      }));
    }
  }

  const setSuccessMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'success',
        alertTitle: 'Success',
        alertMessage: message
      }));
    }
  };

  return (
    <>
      <ReactTooltip id="interventions-actions-tooltip" />
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
      <div className="container-fluid">
        <table class="table">
          <thead className="table-light">
            <tr>
              <th>Intervention</th>
              <th>View</th>
              <th>Started</th>
              <th>Ended</th>
            </tr>
          </thead>
          <tbody>
            {userInterventions.length > 0 && userInterventions.map((intervention) => {
              return (
              <tr>
                <td><Link to={`/interventions/${intervention.uuid}`} >{intervention.name}</Link></td>
                <td>
                  <Link to={`/views/${intervention.view.uuid}`}>{intervention.view.name}</Link>
                </td>
                <td>
                  {intervention.started_at}
                </td>
                <td>
                  {intervention.ended_at}
                </td>
              </tr>
              )
            })}
          </tbody>
        </table>
     
      <a className="btn btn-secondary" href={`/interventions/add`} role="button">Add Intervention</a>
      </div>
    </>
  )
}
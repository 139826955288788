import api from '../interceptors/auth.interceptor';

class UserGraphsService {
  getAll() {
    return api.get(process.env.REACT_APP_API_URL + '/api/user/graphs');
  }

  destroy(graphId) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/user/graphs/${graphId}`);
  }

  // get(graphId) {
  //   return api.get(process.env.REACT_APP_API_URL + `/api/graphs/${graphId}`);
  // }

  // update(graphId, graphData) {
  //   return api.post(process.env.REACT_APP_API_URL + `/api/graphs/${graphId}`, graphData);
  // }

  // create(graphData) {
  //   return api.post(process.env.REACT_APP_API_URL + `/api/user/graphs/`, 
  //     {graph: graphData}
  //   );
  // }
}

const userGraphsService = new UserGraphsService();
export default userGraphsService;
import { useState, useEffect } from 'react';

import Alert from './alert.component';

import missingTestsService from '../services/missing-tests.service.js';
import viewService from '../services/view.service';

import addTitleToDefinition from '../utils/add-title-to-definition';

export default function MissingTestsPage() {

  const [missingDefinitions, setMissingDefinitions] = useState({});
  const [definitionsToRetest, setDefinitionsToRetest] = useState([]);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  const setFailureMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'danger',
      alertTitle: 'Error',
      alertMessage: message
    }));
  }

  const setSuccessMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'success',
      alertTitle: 'Success',
      alertMessage: message
    }));
  };

  useEffect(() => {
    viewService.getAll().then(
      response => {
        const views = response.data.views;
        for (const view of views) {
          missingTestsService.getMissingTests(view.id).then(
            response => {
              for (const definition of response.data.definitions) {
                addTitleToDefinition(definition);
              }
              const newDefinitions = Object.values(
                response.data.definitions.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
              );
              setMissingDefinitions(missingDefinitions => ({...missingDefinitions, [view.id]: {view: view, definitions: newDefinitions}}))
            },
            error => {
              setFailureMessage(`Failed to load missing tests for view ${view.name}`);
            }
          )
        }
      },
      error => {
        setFailureMessage('Failed to load views');
      }
    )
  }, []);

  useEffect(() => {
    missingTestsService.getRequiredRetests().then(
      response => {
        for (const definition of response.data.definitions) {
          addTitleToDefinition(definition);
        }
        setDefinitionsToRetest(response.data.definitions);
      },
      error => {
        if (error.status === 404) {
          setFailureMessage('No required retests found');
        } else {
          setFailureMessage('Failed to load required retests');
        }
      }
    )
  }, []);

  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <div className="btn-group" role="group" aria-label="Missing Tests">
        <button className="btn btn-primary me-3" type="button" data-bs-toggle="collapse" data-bs-target="#retestDefinitionsCard" aria-expanded="false" aria-controls="collapseExample">
          Its Time To Re-Test
        </button>
        <button className="btn btn-primary mr-3" type="button" data-bs-toggle="collapse" data-bs-target="#missingDefinitionsCard" aria-expanded="false" aria-controls="collapseExample">
          See which tests are missing for a view
        </button>
      </div>
      <div className="collapse" id="retestDefinitionsCard">
        <div className="card card-body">
          <ul className="list-group" name="definitions">
          {definitionsToRetest.length > 0 && definitionsToRetest.map((definition) => (
            <>
             <li key={`list-group-item-${definition.id}`} className="list-group-item">
                {definition.title}
              </li>
            </>
          ))
          }
          </ul>
        </div>
      </div>
      <div className="collapse card-group" id="missingDefinitionsCard">
      {Object.keys(missingDefinitions).length > 0 && Object.keys(missingDefinitions).map((viewId) =>
        <div className="card" key={viewId}>
          <div className="card card-body">
            <div className="card-title">
              {missingDefinitions[viewId].view.name}
            </div>
            <ul className="list-group" name="definitions">
              {missingDefinitions[viewId].definitions.map((definition) => (
              <>
              <li key={`list-group-view-${viewId}-item-${definition.id}`} className="list-group-item">
                  {definition.title}
                </li>
              </>
            ))}
            </ul>
          </div>
        </div>
      )}
      </div>
    </>
  )
}
import api from '../interceptors/auth.interceptor';

class CategoryEntriesService {
  getAll(categoryId, applicationId) {
    if (applicationId) {
      return api.get(process.env.REACT_APP_API_URL + `/api/categories/${categoryId}/${applicationId}/entries/`)
    } else {
      return api.get(process.env.REACT_APP_API_URL + `/api/categories/${categoryId}/entries/`)
    }
  }

  getAllByUserName(userName, categoryId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/users/${userName}/categories/${categoryId}/entries/`)
  }

  getAllByShareUuid(shareUuid, categoryId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/public/shared/${shareUuid}/categories/${categoryId}/entries/`)
  }
}


const categoryEntriesService = new CategoryEntriesService();
export default categoryEntriesService;
import api from '../interceptors/auth.interceptor';

class UsersService {

  get(userName) {
    return api.get(process.env.REACT_APP_API_URL + `/api/users/${userName}`);
  }

  getAll() {
    return api.get(process.env.REACT_APP_API_URL + `/api/users/`);
  }

  getUsersWithAccess() {  
    return api.get(process.env.REACT_APP_API_URL + `/api/users/with-access`);
  }

  getAllowedData(userName) {
    return api.get(process.env.REACT_APP_API_URL + `/api/users/${userName}/allowed-data`);
  }

  getGroup(userName, groupUuid) {
    return api.get(process.env.REACT_APP_API_URL + `/api/users/${encodeURIComponent(userName)}/groups/${groupUuid}`);
  }

  getCategory(userName, categoryId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/users/${encodeURIComponent(userName)}/categories/${categoryId}}`);
  }


}

const usersService = new UsersService();
export default usersService;
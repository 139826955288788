import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";

import Form from "react-validation/build/form";
import capitalizeFirstLetter from '../utils/capitalize-first-letter';

import DefinitionSelector from "./definition-selector.component"
import Alert from "./alert.component"

import graphsService from '../services/graphs.service';

import addTitleToDefinition from "../utils/add-title-to-definition"
export default function GraphPage() {
  const navigate = useNavigate();

  const [newGraphFlag, setNewGraphFlag] = useState(false);
  const [graph, setGraph] = useState({});
  const [originalGraph, setOriginalGraph] = useState({});
  const [isEditDefinitionMode, setIsEditDefinitionMode] = useState(false);
  const [isEditNameMode, setIsEditNameMode] = useState(false);
  const [isEditDescriptionMode, setIsEditDescriptionMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [definitionsPicked, setDefinitionsPicked] = useState([])
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { graphId } = useParams();

  useEffect(() => {

    graphsService.get(graphId).then(
      response => {
        const graph = response.data.graph;

        for (const definition of graph.definitions) {
          addTitleToDefinition(definition)
        }
        setGraph(graph);
        setOriginalGraph(graph);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [graphId])

  
  const hideMessage = () => {
    setAlert(alert => ({...alert, 
      show: false,
    }));
  }

  const setFailureMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: message
      }));
    }
  }

  const setSuccessMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'success',
        alertTitle: 'Success',
        alertMessage: message
      }));
    }
  };

  const onDeleteClicked = (e) => {
    e.preventDefault();


    if (!window.confirm('Delete the group?')) {
      return;
    }
    
    hideMessage();
    setLoading(true);

    graphsService.delete(graph.id).then(
      response => {
        if (response.data.success) {
          hideMessage()
          navigate(`/graphs`, {state: {message: response.data.message}});
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setFailureMessage(resMessage);
        });
  }

  const onDuplicateClicked = (e) => {
    e.preventDefault();

    hideMessage();
    setLoading(true);

    graphsService.duplicate(graphId).then(
      
      (response) => {
        if (response.data.success) {
          hideMessage()
          navigate(`/dashboard/${response.data.graph.uuid}`, {state: {message: response.data.message}});
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setFailureMessage(resMessage);
        });
  }

  const handleSubmit= (e) => {
    e.preventDefault();

    hideMessage()
    setLoading(true);

    let remoteAction;

    if (newGraphFlag) {
      const newGraph = {
        name: 'New Graph',
      }
      remoteAction = graphsService.create(newGraph);
    } else {
      remoteAction = graphsService.update(graph.id, graph);
    }

    remoteAction.then(
      response => {
        if (newGraphFlag) {
          navigate(`/graphs/${response.data.graph.uuid}`, {state: {message: response.data.message}});
        }
        if (response.data.success && response.data.message) {
          setSuccessMessage(response.data.message)
        }
        const updatedGraph = response.data.graph;
        setGraph(updatedGraph);
        setOriginalGraph(graph);
        setLoading(false);
        setNewGraphFlag(false);
      }, error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setFailureMessage(resMessage);
      })
  }

  const setHandleKeyup = (fieldName, editModeFn) => {
    return (evt) => {
      // event code 27 means escape key
      if (evt.keyCode === 27) {
          setGraph({...graph, [fieldName]: originalGraph[fieldName]});
          editModeFn(false);
          return false;
      } else if (evt.keyCode === 13) {
          editModeFn(false);
          return false;
      }
    }
  }

  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      {graph.id &&
         (
          <div key={graph.uuid} className='col-xs-12 col-sm-12'>
            <div className="row">
              {isEditNameMode ? (
                <div className="input-group mb-3">
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder={graph.name} 
                    aria-label="Graph name" 
                    aria-describedby="Graph-name"
                    value={graph.name}
                    onKeyUp={setHandleKeyup('name', setIsEditNameMode)}
                    onChange={(event) => setGraph({...graph, name: event.target.value})} />
                </div>
                ) : (
                  <h2 style={{'display': 'inline'}}>
                    {capitalizeFirstLetter(graph.name)}
                  </h2>
                )}
                {!isEditNameMode && (
                      <i 
                        className="bi bi-pen"
                        onClick={(e) => setIsEditNameMode(true)}
                      >
                      </i>)}
            </div>
            <div className="row">
              {isEditDescriptionMode ? (
                <div className="input-group mb-3">
                  <textarea 
                    type="text" 
                    className="form-control" 
                    placeholder={graph.name} 
                    aria-label="Graph name" 
                    aria-describedby="Graph-name"
                    value={graph.description}
                    onKeyUp={setHandleKeyup('description', setIsEditDescriptionMode)}
                    onChange={(event) => setGraph({...graph, description: event.target.value})} />
                </div>
                ) : 
                  graph.description
                }
                {!isEditDescriptionMode && (
                      <i 
                        className="bi bi-pen"
                        onClick={(e) => setIsEditDescriptionMode(true)}
                      >
                      </i>)}
            </div>
            <div className="row">

              <button className="btn btn-primary btn-block" onClick={(e) => {setIsEditDefinitionMode(!isEditDefinitionMode)}} disabled={loading}>Edit Definitions</button>
              {isEditDefinitionMode ? 
                (<DefinitionSelector
                  setFailureMessage={setFailureMessage} 
                  setSuccessMessage={setSuccessMessage}
                  onDefinitionsPicked={(pickedDefinitions) => setGraph({...graph, definitions: pickedDefinitions})}
                  allowFilter={true}
                  initialValue={definitionsPicked}
                />
                ) : 
                graph.definitions && graph.definitions.map((definition) => {
                  return (
                    <div className="col-sm-2" key={`graph-${definition.id}`}>
                      <div className="card" style={{width: '18rem'}}>
                        <div className="card-body">
                          <h5 className="card-title">{definition.name}</h5>
                          <p className="card-text">{definition.description}</p>
                        </div>
                      </div>
                      
                    </div>
                  )
                }
              )}
              <button className="btn btn-primary btn-block" onClick={(e) => {setIsEditDefinitionMode(!isEditDefinitionMode)}} disabled={loading}>Edit Definitions</button>
            </div>

            <Form onSubmit={handleSubmit} className='form-horizontal'>

              <div className="btn-toolbar" role="toolbar">
                <div className="btn-group mr-2" role="group">
                  <button
                    className="btn btn-secondary me-2"
                    disabled={loading || newGraphFlag}
                    onClick={onDuplicateClicked}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Duplicate</span>
                  </button>

                  <button
                    className="btn btn-primary me-2"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Submit</span>
                  </button>
                  <button
                    className="btn btn-danger me-2"
                    disabled={loading || newGraphFlag}
                    onClick={onDeleteClicked}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            </Form>
          </div>)}
    </>
  )
}
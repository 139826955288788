import api from '../interceptors/auth.interceptor';

class ViewService {
  getAll() {
    return api.get(process.env.REACT_APP_API_URL + '/api/views');
  }

  get(viewUuid) {
    return api.get(process.env.REACT_APP_API_URL + `/api/views/${viewUuid}`);
  }

  duplicate(viewUuid, applicationId) {
    return api.post(process.env.REACT_APP_API_URL + `/api/views/${viewUuid}/duplicate`, {
      application_id: applicationId
    });
  }

  searchByDefinitionName(definitionName) {
    return api.get(process.env.REACT_APP_API_URL + `/api/views/categories/definitions/${definitionName}`);
  }

  update(viewUuid, viewData) {
    return api.post(process.env.REACT_APP_API_URL + `/api/views/${viewUuid}`, {view: viewData});
  }

  create(viewData) {
    return api.post(process.env.REACT_APP_API_URL + '/api/views', {view: viewData});
  }

  getCategories(viewUuid) {
    return api.get(process.env.REACT_APP_API_URL + `/api/views/${viewUuid}/categories`);
  }

  createCategory(viewUuid, category) {
    return api.post(process.env.REACT_APP_API_URL + `/api/views/${viewUuid}/categories`, 
    { 
      category: category,
    });
  }

  addCategory(viewUuid, categoryId) {
    return api.post(process.env.REACT_APP_API_URL + `/api/views/${viewUuid}/categories/${categoryId}`)
  }

  deleteCategory(viewUuid, categoryId) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/views/${viewUuid}/categories/${categoryId}`)
  }
}

const viewService = new ViewService();
export default viewService;
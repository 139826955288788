import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Alert from './alert.component';

import authService from '../services/auth.service';

export default function VerifyEmail() {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  }); 

  const navigate = useNavigate();

  const { token } = useParams();
  
  useState(() => {
    setLoading(true);
    authService.verifyEmail(token).then(
      response => {
        if (response.data.message) {
          localStorage.setItem("last-message", response.data.message);
        }
        navigate("/");
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: resMessage,
          alertTitle: 'Error'
        }));
      }
    )
  }, [])

  return (
    <>
      {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <div className="col-md-12">
        <div className="card card-container text-center mx-auto">
         <div className="mb-3">
            <label htmlFor="password">Verifying your email address</label>
          </div>
        </div>
      </div>
    </>
  )
}